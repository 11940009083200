import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { FIRESTORE } from '../../../../Firebase/firestore';
import Select from "react-select";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { doc, setDoc, collection, CollectionReference } from "firebase/firestore";
import { PromptData } from '../Manage/models';
import Swal from "sweetalert2";


export default function PromptTestData() {
    const firestore = useFirestore();
    const testDataCollection = collection(firestore, FIRESTORE.PROMPT_TEST_DATA) as CollectionReference<PromptData>;
    const { data: datasets, status } = useFirestoreCollectionData(testDataCollection, { idField: "id" });
    const datasetNames = datasets?.map(d => ({ value: d, label: d.name }))
    const dispatch = useDispatch();
    const [selectedDataset, setSelectedDataset] = useState<PromptData | undefined>();
    const [loading, setLoading] = useState(false);

    const schema = yup
        .object({
            name: yup.string().required("Data is a required in JSON format"),
            jsonData: yup.string().required("Data is a required in JSON format")
        })
        .required();

    const { register, control, reset, handleSubmit, formState: { isDirty, errors, isValid } } =
        useForm<{ name: string, jsonData: string }>({ mode: "all", resolver: yupResolver(schema), defaultValues: {} }); //, defaultValues: basicInfo

    useEffect(() => {
        if (selectedDataset) {
            const jsonData = JSON.stringify(selectedDataset.data, null, 4);
            const name = selectedDataset.name;
            reset({ name, jsonData });
        }
    }, [selectedDataset, reset]);

    const onSubmit = async ({ name, jsonData }: { name: string, jsonData: string }, e: any) => {
        try {
            const parsed = JSON.parse(jsonData);
            const dataset: PromptData = { id: name, name: name, data: parsed };
            const datasetRef = doc(firestore, FIRESTORE.PROMPT_TEST_DATA, dataset.name);
            await setDoc(datasetRef, { name, data: parsed }, { merge: true });
        } catch (err) {
            //invalid format
            const message = (err as any).message;
            const errorPosition = parseInt(message.split("position ").pop().trim());
            const OFFSET = 20;
            // alert(`${errorPosition}, ${Math.max(0, errorPosition - OFFSET)}, ${errorPosition+OFFSET}, ${Math.min(errorPosition+OFFSET, jsonData.length)}, ${Math.min(errorPosition+OFFSET+OFFSET+OFFSET, jsonData.length)}, ` + 
            // `${jsonData.substring(Math.max(0, errorPosition - OFFSET), errorPosition)}`)
            const result = await Swal.fire({
                title: "Invalid Format",
                html: `<p style="color:red">${(err as any).message}</p>` +
                    `<p><i><span>${jsonData.substring(Math.max(0, errorPosition - OFFSET - OFFSET), Math.max(0, errorPosition - OFFSET))}</span>` +
                    `<span style="color:red">${jsonData.substring(Math.max(0, errorPosition - OFFSET), errorPosition + OFFSET)}</span>` +
                    `<span>${jsonData.substring(Math.min(errorPosition + OFFSET, jsonData.length), Math.min(errorPosition + OFFSET + OFFSET + OFFSET, jsonData.length))}</span></i></p>` +
                    `<p>Try <a href="https://jsonlint.com/" target="_blank" style="color:blue">Online JSON Checker</a> or <a href="https://www.w3schools.com/js/js_json_syntax.asp" style="color:blue" target="_blank">JSON Help</a></p>`,
                allowOutsideClick: true,
                confirmButtonText: "Close",
                showCancelButton: false,
            });
        }

    }

    return (
        <section className="card-body col-sm-9">
            <Form id="dataset-form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className="control-group form-group text-black">
                    <Form.Label className="form-label">
                        Dataset
                    </Form.Label>
                    <Select
                        onChange={(sel) =>
                            setSelectedDataset(sel?.value)
                        }
                        options={datasetNames}

                        value={datasetNames?.find(p => p.value.id == selectedDataset?.id)}
                        classNamePrefix="formselect"
                        isSearchable
                        placeholder="Select a dataset"
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Dataset Name
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Business Name"
                        {...register("name")}
                    />
                    <p className={"text-error"}>
                        {errors?.name?.message}
                    </p>
                </FormGroup>

                <FormGroup className="control-group form-group">
                    <textarea
                        className="form-control"
                        {...register("jsonData")}
                        placeholder="Prompt Text"
                        rows={10}
                    ></textarea>

                </FormGroup>
                <FormGroup className="control-group form-group">
                    {loading ?
                        <div className="text-center">
                            <div className="lds-dual-ring"></div>
                        </div>
                        :
                        <button className="btn btn-primary " type={"submit"}>
                            Save
                        </button>
                    }
                </FormGroup>
            </Form>
        </section>
    );
}
