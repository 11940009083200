import React, { useState } from 'react'
import { Accordion, Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import Select from "react-select";

interface HighlightedFeatures {
    customerType: string;
    feature: string;
}
function Segment() {
    const options = [{ value: 'under 25', label: 'Under 25' }];
    const [selectedOption, setSelectedOption] = useState<any>();
    const [customerType, setCustomerType] = useState('');
    const [hightlightedFeatures, setHighlightedFeatures] = useState<HighlightedFeatures[]>([]);
    const [featureError, setFeatureError] = useState<HighlightedFeatures>({ customerType: '', feature: '' });

    const handleHightlightFeatures = () => {
        if (customerType && selectedOption?.value) {
            setFeatureError({ customerType: '', feature: '' });
            let tempFeatures = { customerType, feature: selectedOption?.value };
            console.log({ tempFeatures });
            let featureExist = hightlightedFeatures?.filter((value) => value?.customerType == customerType && value?.feature == selectedOption?.value);
            if (featureExist && featureExist?.length == 0) {
                setHighlightedFeatures((prevState: HighlightedFeatures[] | undefined) => {
                    if (prevState) {
                        return [...prevState, tempFeatures];
                    }
                    return [tempFeatures];
                });
            }
            setCustomerType('');
            setSelectedOption({});
        } else {
            if (!customerType && !selectedOption?.value) {
                setFeatureError({ customerType: 'Customer type is a required field', feature: 'Feature is a required field' });
            }
            else if (!customerType) {
                setFeatureError({ customerType: 'Feature title is a required field', feature: '' });
            }
            else if (!selectedOption?.value) {
                setFeatureError({ customerType: '', feature: 'Feature description is a required field' });

            }

        }
    }

    const removeFeature = (feature: HighlightedFeatures) => {
        let filteredFeatures = hightlightedFeatures?.filter((value) => value?.customerType != feature?.customerType && value?.feature != feature?.feature);
        if (filteredFeatures) {
            setHighlightedFeatures([...filteredFeatures]);
        }
    }
    return (
        <div>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">Segments</span>
                </div>
            </div>
            <Row>
                <Col lg={12} md={12}>
                    <Card>
                        <Card.Body>
                            <FormGroup className="control-group form-group">
                                <Form.Label className="form-label">Identify your target customer segments</Form.Label>
                                <Row className="accordiondclose mt-3">
                                    <div
                                        aria-multiselectable="true"
                                        className="accordion accordion-dark w-100"
                                        id="accordion2"
                                        role="tablist"
                                    >
                                        <div className="card mb-0">
                                            <Accordion defaultActiveKey="0" flush>
                                                {hightlightedFeatures?.map((value, index) => {
                                                    return <Accordion.Item eventKey={`${index}`} key={value?.feature}>
                                                        <Accordion.Header
                                                            className=""
                                                            id="headingOne2"
                                                            role="tab"
                                                        >
                                                            <div className="w-100 d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <i className="fe fe-plus-circle me-2"></i>{value?.customerType}
                                                                </div>
                                                                <p className="mb-0 btn btn-light me-2" onClick={() => removeFeature(value)}>Remove</p>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body className="borders">
                                                            {value?.feature}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                })}
                                            </Accordion>
                                        </div>

                                    </div>
                                </Row>
                                <Form.Control
                                    type="text"
                                    className="form-control"
                                    value={customerType}
                                    onChange={(e) => setCustomerType(e?.target?.value)}
                                    placeholder="Customer Type"
                                />
                                {featureError?.customerType && <p className={"text-error"}>
                                    {featureError?.customerType}
                                </p>}
                                <div className="mt-3">
                                    <div className="SlectBox">
                                        <Select
                                            value={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            placeholder="Features important to this customer"
                                            classNamePrefix="selectform"
                                        />
                                        {featureError?.feature && <p className={"text-error"}>
                                            {featureError?.feature}
                                        </p>}
                                    </div>
                                </div>

                            </FormGroup>
                            <Button variant="primary" className="mt-3" onClick={handleHightlightFeatures}>
                                Add
                            </Button>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Segment