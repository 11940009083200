import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Dropdown, Form, FormGroup, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import {ProductPricingAndPackages } from "../../../../redux/active-product/model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../../redux/store/store';
import JoditEditor from "jodit-react";
import { updateStep } from '../../../../redux/active-product/currentStepSlice';
import { updatePricingAndPackages } from '../../../../redux/active-item/active-itemSlice';


export default function PricingAndPackages() {
    const schema = yup
        .object({})
        .required();
    const pricingAndPackages = useSelector((state: RootState) => state.activeItem.activeProduct.pricingAndPackages);

    const { register, control, handleSubmit, reset, watch, formState: { isDirty, errors, isValid } } =
        useForm<ProductPricingAndPackages>({ mode: "all", resolver: yupResolver(schema), defaultValues: pricingAndPackages });

    const pricing = watch("pricing"); 
    const delivery = watch("delivery");
    const supportAndMaintenance = watch("supportAndMaintenance");

    useEffect(() => {
        if(pricing != pricingAndPackages?.pricing || delivery != pricingAndPackages?.delivery || supportAndMaintenance != pricingAndPackages?.supportAndMaintenance){
            dispatch(updatePricingAndPackages({
                pricing: pricing ?? '',
                delivery: delivery ?? '',
                supportAndMaintenance: supportAndMaintenance ?? '',
            }))
        }
    }, [pricing, delivery, supportAndMaintenance])
    
    useEffect(() => {
        if (pricingAndPackages) {
            const { pricing, delivery, supportAndMaintenance } = pricingAndPackages;
            reset({ pricing, delivery, supportAndMaintenance });
        }
    }, [pricingAndPackages, reset]);
    const dispatch = useDispatch();

    const handleBasicInfo = (data: any) => {
        dispatch(updateStep({title: 'Success stories', step: 4}));
    }

    return (
        <section className="card-body">
            <Form onSubmit={handleSubmit(handleBasicInfo)} id="Pricing and packages">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Pricing:
                    </Form.Label>
                    <textarea
                        className="form-control"
                        placeholder="Pricing"
                        rows={5}
                        {...register("pricing")}
                    ></textarea>
                    <p className={"text-error"}>
                        {errors?.pricing?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Delivery or implementation:
                    </Form.Label>
                    <textarea
                        className="form-control"
                        placeholder="Delivery"
                        rows={5}
                        {...register("delivery")}
                    ></textarea>
                    <p className={"text-error"}>
                        {errors?.delivery?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Support and maintenance:
                    </Form.Label>
                    <textarea
                        className="form-control"
                        placeholder="Support and maintenance"
                        rows={5}
                        {...register("supportAndMaintenance")}
                    ></textarea>
                    <p className={"text-error"}>
                        {errors?.supportAndMaintenance?.message}
                    </p>
                </FormGroup>
            </Form>

        </section>
    );
}
