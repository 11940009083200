
interface HighlightedFeatures {
    title: string;
    description: string;
}

export interface ProductViewModel {
    id: string;
    businessName: string;
    clientId: string;
    sku:string;
    productName: string;
    description: string;
    hightlightedFeatures?: HighlightedFeatures[];
    status:'active'|'deleted';
    image:string;
    gallery:Gallery[];
    benefits?: string[];
    pricing?: string;
    delivery?: string;
    supportAndMaintenance?: string;
    testimonials?: ProductTestimonial[];
    eventsOrActivities?: string[];
    newsOrExogenous?: string[];
    complianceAndcertifications?: string[];
    successStories?: ProductTestimonial[];    
}
export interface Gallery{
        url:string,
        thumbnail:string,
        type?:string
}
export interface ListingRequest {
    product: Product,
  }
  
export interface Product {
    id: string;
    businessName: string;
    clientId: string;
    sku:string;
    productName: string;
    description: string;
    hightlightedFeatures?: HighlightedFeatures[];
    status:'active'|'deleted';
    createdAt?:string;
    lastUpdated?:string;
    image:string;
    gallery:Gallery[];
    benefits?: string[];
    pricing?: string;
    delivery?: string;
    supportAndMaintenance?: string;
    testimonials?: ProductTestimonial[];
    eventsOrActivities?: string[];
    newsOrExogenous?: string[];
    complianceAndcertifications?: string[];
    successStories?: ProductTestimonial[];
}

export enum PRODUCT_STATUS {
    ACTIVE = "active",
    DELETED = "deleted",
  }

  export interface GooglePlace {
   place_id: string;
   international_phone_number: string;
   formatted_phone_number: string;
   formatted_address: string;
   name: string;
   rating: number;
   types: string[];
   business_status: string;
   user_ratings_total: number;
   photos: {
    url: string;
    thumbnail: string;
   }[];
   website: string;
   price_level: number;
   icon: string;
   icon_background_color: string;
   icon_mask_base_uri: string;
   emails?: string[];
   source?: string;
   webScrape?: WebScrape;
}

export interface WebScrape {
    domain: string;
    organization: string;
    description: string;
    twitter: string;
    facebook: string;
    linkedin: string;
    instagram: string;
    youtube: string;
    emails: [
        {value: string;
        type: "personal" | "generic",
        confidence: number;
        first_name?: string;
        last_name?: string;
        position?: string;
        seniority?: string;
        department?: string;
        linkedin?: string;
        twitter?: string;
        phone_number?: string;
        verification: {
            date: string;
            status: "valid"
        }
        }
    ],
    linked_domains: string[];
}

export interface LeadSourceAndContact {
    name: string;
    email: string;
    place_id:string;
    source?: string;
}

export interface GooglePlaces {
   pagetoken: string;
   places: GooglePlace[];
}

export interface CampaignComposedEmail extends LeadSourceAndContact {
    subject: string;
    body: string;
    image: string;
    name: string;
    attachments?: string[];
    phoneNumber: string;
    location?: string;
    approved?: boolean;
    sent?: boolean;
    readyToSend?: boolean;
    type: "general" | "personalized";
}
export interface ProductTestimonial {
    customerType: string;
    name: string;
    date: string;
    rating: number;
    description: string;
    link?:string;
  }