import { firebaseConfig } from "../Firebase/firebase";
import { v4 as uuid } from 'uuid';

export const generateRandomId = () => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 30; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  export const emailsForWebsite = (urlString?: string) => {
    if(!urlString)
      return ["test@gmail.com"];
      const url = new URL(urlString);
      const emailHost = url.hostname.split(".").slice(-2).join(".");

      return [`info@${emailHost}`];
  }

  export const websiteHostUrl = (urlString?: string) => {
    if(!urlString)
      return [""];
      const url = new URL(urlString);
      const host = url.hostname;
      return host;
  }


  export const generateShortRandomId = () => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  export const generateShortRandomNumber = () => {
    var result = '';
    var characters = '1234567890';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  export const cloneNumberStampedId = (oldId: string) => {
    const newId = oldId.split("-").slice(0, -1).join("-") + "-" + generateShortRandomNumber();
    return newId;
  }

  export const cloneName = (oldName: string) => {
    const parts = oldName.split(" - copy");
    let newId = parts[0] + " - copy";
    if (parts.length > 1) {
      if(!isNaN(parseInt(parts[1]))) {
        let index = +parts[1].trim() + 1;
        newId += " " + index;
      } else {
        newId += " 2" ;
      }
    }

    return newId;
  }

  export const shorten = (str?: string, maxLen: number = 3) => {
    return str ? str.length > maxLen ? str.substring(0, maxLen) + "..." : str : "";
  }

  export const campaignToneData = [
    {
        title: 'Audience',
        categories: ['General', 'Knowledgeable', 'Expert'],
        hint: 'Knowledgeable (default): Requires focus to read and understand.'
    },
    {
        title: 'Formality',
        categories: ['Informal', 'Neutral', 'Formal'],
        hint: 'Neutral (default): Restricts slang but allows standard casual expressions.'
    },
    {
        title: 'Domain',
        categories: ['Academic', 'Business', 'General', 'Email', 'Casual', 'Creative'],
        hint: 'Get customized suggestions for business writing, academic assignments, and more.'
    },
    {
        title: 'Intent',
        categories: ['Inform', 'Describe', 'Convince', 'Tell A Story'],
        hint: `Experimental. What are you trying to do? This helps us build new suggestions and won't affect your feedback today.`
    },
    {
      title: 'Subject Line Preference',
      categories: ['Personalized', 'Benefit focused', 'Urgent'],
      hint: `A study investigating the impact of subject line content on email open rates and user engagement.`
  },
];

export const getNameFromImageUrl = (url?: string) => {
  return url?.split("?")?.[0]
  ?.split("/")
  ?.pop()
  ?.split("%2F")
  ?.pop()
}

export const uniqueId = () => uuid();

export const productUrl = (image: string, size: number = 680) => {
    if(!image)
      return image;
    if(image.includes("680") && image.includes("thumbnails"))
      return image; 
    else if(image.includes("token="))
      return image; //specific permissions, suffixing to point to a new file wont work
    const unescaped = image?.split("%2F")?.join("/");
    if(firebaseConfig.imageOptimizedUrls.some((item) => unescaped?.includes(item))) {
      const path = unescaped.split("/").slice(0, -1).join("/");
      const file = unescaped.split("/").pop();
      let path1 = path;
      if(path?.split("/").pop()?.length === 36) {
        path1 = unescaped.split("/").slice(0, -2).join("/");
      }
      let newName;
      
      if(file?.includes("."))
        newName = file.split(".").slice(0, -1).join(".") + `_${size ?? 680}x${size ?? 680}.` + file.split(".").pop();
      else if(file?.includes("?"))
        newName = file.split("?").slice(0, -1).join("?") + `_${size ?? 680}x${size ?? 680}?` + file.split("?").pop();
      else
        newName = file + `_${size ?? 680}x${size ?? 680}`;
      return `${path1}/thumbnails/${newName}`;
    } else
      return image;
  };

export const arraysEqual = (a: string[], b: string[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function titleUpperCase(str: string) {
  if(!str)
    return "";
  return str.replace(/_/g, ' ').toLowerCase().split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}