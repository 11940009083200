import React, { useEffect, useState } from 'react';
import {  Button, Card, Dropdown, Form, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/store';
import { updateProductComplianceAndcertifications, updateProductEventsOrActivities, updateProductNewsOrExogenous, updateProductTestimonial, } from '../../../../redux/active-item/active-itemSlice';
import { ProductTestimonial } from '../../../../models/product';


export default function TestimonialsAndSocialProof() {

    const dispatch = useDispatch();

    const testimonials = useSelector((state: RootState) => state.activeItem.activeProduct.testimonialAndSocialProof.testimonials);
    const [testimonialTitle, settestimonialTitle] = useState<string>('');
    const [testimonialCustomerType, settestimonialCustomerType] = useState<string>('');
    const [testimonialDescription, settestimonialDescription] = useState<string>('');
    const [testimonialRating, settestimonialRating] = useState<number>(0);
    const [testimonialDate, settestimonialDate] = useState<string>('');
    
    const [testimonialNError, settestimonialNError] = useState<string>("");
    const [testimonialCTError, settestimonialCTError] = useState<string>("");
    const [testimonialDError, settestimonialDError] = useState<string>("");

    const handlesuccessStories = async () => {
        if (testimonialTitle && testimonialDescription && testimonialCustomerType) {
            settestimonialDError("");
            settestimonialCTError("");
            settestimonialNError("");
            let testimonial: ProductTestimonial = {
                name: testimonialTitle,
                description: testimonialDescription,
                customerType: testimonialCustomerType,
                date: testimonialDate,
                rating: testimonialRating,
            }
            dispatch(updateProductTestimonial([...testimonials?.filter(i => i != testimonial), testimonial]));
            settestimonialTitle("");
            settestimonialCustomerType("");
            settestimonialDate("");
            settestimonialDescription("");
            settestimonialRating(0);
        } else {
            if (!testimonialTitle) {
                settestimonialNError("Title is a required field");
            }
            if (!testimonialDescription) {
                settestimonialDError("Description is a required field");
            }
            if (!testimonialCustomerType) {
                settestimonialCTError("Customer Type is a required field");
            }

        }
    };

    const removeTestimonial = (story: ProductTestimonial) => {
        let filteredStories = testimonials?.filter(
            (value) =>
            value != story
        );
        if (filteredStories) {
            dispatch(updateProductTestimonial(filteredStories));
        }
    };

    const eventsOrActivities = useSelector((state: RootState) => state.activeItem.activeProduct.testimonialAndSocialProof.eventsOrActivities);
    const [eventsOrActivitiesTitle, seteventsOrActivitiesTitle] = useState<string>('');
    const [eventsOrActivitiesError, seteventsOrActivitiesError] = useState<string>("");

    const handleeventsOrActivities = async () => {
        if (eventsOrActivitiesTitle) {
            seteventsOrActivitiesError("");
            dispatch(updateProductEventsOrActivities([...eventsOrActivities?.filter(i => i != eventsOrActivitiesTitle), eventsOrActivitiesTitle]));
            seteventsOrActivitiesTitle("");
        } else {
            if (!eventsOrActivitiesTitle) {
                seteventsOrActivitiesError("Event is a required field");
            }
        }
    };

    const removeeventsOrActivity = (story: string) => {
        let filteredStories = eventsOrActivities?.filter(
            (value) =>
            value != story
        );
        if (filteredStories) {
            dispatch(updateProductEventsOrActivities(filteredStories));
        }
    };


    const newsOrExogenous = useSelector((state: RootState) => state.activeItem.activeProduct.testimonialAndSocialProof.newsOrExogenous);
    const [newsOrExogenousTitle, setnewsOrExogenousTitle] = useState<string>('');
    const [newsOrExogenousError, setnewsOrExogenousError] = useState<string>("");

    const handlenewsOrExogenous = async () => {
        if (newsOrExogenousTitle) {
            setnewsOrExogenousError("");
            dispatch(updateProductNewsOrExogenous([...newsOrExogenous?.filter(i => i != newsOrExogenousTitle), newsOrExogenousTitle]));
            setnewsOrExogenousTitle("");
        } else {
            if (!newsOrExogenousTitle) {
                setnewsOrExogenousError("News is a required field");
            }
        }
    };

    const removenewsOrExogenous = (story: string) => {
        let filteredStories = newsOrExogenous?.filter(
            (value) =>
            value != story
        );
        if (filteredStories) {
            dispatch(updateProductNewsOrExogenous(filteredStories));
        }
    };


    const complianceAndcertifications = useSelector((state: RootState) => state.activeItem.activeProduct.testimonialAndSocialProof.complianceAndcertifications);
    const [complianceAndcertificationsTitle, setcomplianceAndcertificationsTitle] = useState<string>('');
    const [complianceAndcertificationsError, setcomplianceAndcertificationsError] = useState<string>("");

    const handlecomplianceAndcertifications = async () => {
        if (complianceAndcertificationsTitle) {
            setcomplianceAndcertificationsError("");
            dispatch(updateProductComplianceAndcertifications([...complianceAndcertifications?.filter(i => i != complianceAndcertificationsTitle), complianceAndcertificationsTitle]));
            setcomplianceAndcertificationsTitle("");
        } else {
            if (!complianceAndcertificationsTitle) {
                setcomplianceAndcertificationsError("Compliance and certification is a required field");
            }
        }
    };

    const removecomplianceAndcertification = (story: string) => {
        let filteredStories = complianceAndcertifications?.filter(
            (value) =>
            value != story
        );
        if (filteredStories) {
            dispatch(updateProductComplianceAndcertifications(filteredStories));
        }
    };



    return (
        <section className="card-body">
            <Form id="Testimonials and social proof">
                <FormGroup className="control-group form-group">
                    {testimonials?.length > 0 && <Form.Label className="form-label">
                        Testimonials
                    </Form.Label>}
                    <div className='mt-2'>
                        {testimonials?.map((value, index) => {
                            return <Card className="mg-b-20 border">
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                {value?.name}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            settestimonialTitle(value?.name);
                                                            settestimonialDescription(value?.description);
                                                            settestimonialDate(value?.date);
                                                            settestimonialCustomerType(value?.customerType);
                                                            settestimonialRating(value?.rating);
                                                            removeTestimonial(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removeTestimonial(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value?.description}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={testimonialTitle}
                            onChange={(e) => settestimonialTitle(e?.currentTarget?.value)}
                        />
                        <p className={"text-error"}>
                            {testimonialNError}
                        </p>
                    </FormGroup>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Customer Type
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={testimonialCustomerType}
                            onChange={(e) => settestimonialCustomerType(e?.currentTarget?.value)}
                        />
                        <p className={"text-error"}>
                            {testimonialCTError}
                        </p>
                    </FormGroup>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Rating
                        </Form.Label>
                        <Form.Control
                            type="number" 
                            placeholder=""
                            className="form-control"
                            min={0}
                            max={5}
                            step={1}
                            value={testimonialRating}
                            onChange={(e) => {
                                if(parseFloat(e?.currentTarget?.value) >= 5){
                                    settestimonialRating(5)
                                }else if(parseFloat(e?.currentTarget?.value) <=0){
                                    settestimonialRating(0)
                                }else{
                                    settestimonialRating(parseFloat(e?.currentTarget?.value))
                                }
                            }}
                        />
                        {/* <p className={"text-error"}>
                            {testimonialNError}
                        </p> */}
                    </FormGroup>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Date
                        </Form.Label>
                        <Form.Control
                            type="date"
                            placeholder=""
                            className="form-control"
                            value={testimonialDate}
                            onChange={(e) => settestimonialDate(e?.currentTarget?.value)}
                        />
                        {/* <p className={"text-error"}>
                            {testimonialNError}
                        </p> */}
                    </FormGroup>
                    <textarea
                        className="form-control"
                        placeholder="Description"
                        rows={5}
                        value={testimonialDescription}
                        onChange={(e) => {
                            settestimonialDescription(e?.target?.value);
                        }}
                    ></textarea>
                    {testimonialDError && (
                        <p className={"text-error"}>
                            {testimonialDError}
                        </p>
                    )}
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handlesuccessStories}
                    >
                        Add
                    </Button>
                </FormGroup>
                {/*  */}
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Events Or Activities
                    </Form.Label>
                    <div className='mt-2'>
                        {eventsOrActivities?.map((value, index) => {
                            return <Card className="mg-b-20 border">
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                Event #{index+1}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            seteventsOrActivitiesTitle(value);
                                                            removeeventsOrActivity(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removeeventsOrActivity(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <textarea
                        className="form-control"
                        placeholder=""
                        rows={5}
                        value={eventsOrActivitiesTitle}
                        onChange={(e) => {
                            seteventsOrActivitiesTitle(e?.target?.value);
                        }}
                    ></textarea>
                    {eventsOrActivitiesError && (
                        <p className={"text-error"}>
                            {eventsOrActivitiesError}
                        </p>
                    )}
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handleeventsOrActivities}
                    >
                        Add
                    </Button>
                </FormGroup>
                {/*  */}
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        News Or Exogenous
                    </Form.Label>
                    <div className='mt-2'>
                        {newsOrExogenous?.map((value, index) => {
                            return <Card className="mg-b-20 border">
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                News #{index+1}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            setnewsOrExogenousTitle(value);
                                                            removenewsOrExogenous(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removenewsOrExogenous(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <textarea
                        className="form-control"
                        placeholder=""
                        rows={5}
                        value={newsOrExogenousTitle}
                        onChange={(e) => {
                            setnewsOrExogenousTitle(e?.target?.value);
                        }}
                    ></textarea>
                    {newsOrExogenousError && (
                        <p className={"text-error"}>
                            {newsOrExogenousError}
                        </p>
                    )}
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handlenewsOrExogenous}
                    >
                        Add
                    </Button>
                </FormGroup>
                {/*  */}
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Compliance and Certifications
                    </Form.Label>
                    <div className='mt-2'>
                        {complianceAndcertifications?.map((value, index) => {
                            return <Card className="mg-b-20 border">
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                Compliance #{index+1}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            setcomplianceAndcertificationsTitle(value);
                                                            removecomplianceAndcertification(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removecomplianceAndcertification(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <textarea
                        className="form-control"
                        placeholder=""
                        rows={5}
                        value={complianceAndcertificationsTitle}
                        onChange={(e) => {
                            setcomplianceAndcertificationsTitle(e?.target?.value);
                        }}
                    ></textarea>
                    {complianceAndcertificationsError && (
                        <p className={"text-error"}>
                            {complianceAndcertificationsError}
                        </p>
                    )}
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handlecomplianceAndcertifications}
                    >
                        Add
                    </Button>
                </FormGroup>
            </Form>

        </section>
    );
}
