import React from "react"
import ReactApexChart from "react-apexcharts";
import { Form, FormGroup } from "react-bootstrap";

export class Statistics3 extends React.Component<{}, { options: any, series: any, }>{
  constructor(props: any) {
    super(props);

    this.state = {

      series: [
        {
          name: "active",
          data: [44, 42, 57, 86, 58, 55, 70, 43, 23, 54, 77, 34],
        },
        {
          name: "inactive",
          data: [-34, -22, -37, -56, -21, -35, -60, -34, -56, -78, -89, -53],
        },
      ],
      options: {
        chart: {
          stacked: true,
          type: "bar",
          toolbar: {
            show: false,
          },
          height: 350,
        },
        grid: {
          borderColor: "#f2f6f7",
        },
        colors: ['var(--primary-bg-color)' || "#38cab3", "#e4e7ed"],
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -100,
                  to: -46,
                  color: "#ebeff5",
                },
                {
                  from: -45,
                  to: 0,
                  color: "#ebeff5",
                },
              ],
            },
            columnWidth: "35%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 4,
          colors: ["transparent"],
        },
        legend: {
          show: true,
          position: "top",
        },
        xaxis: {
          type: "month",
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "sep",
            "oct",
            "nov",
            "dec",
          ],
          axisBorder: {
            show: true,
            color: "rgba(119, 119, 142, 0.05)",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "rgba(119, 119, 142, 0.05)",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            rotate: -90,
          },
        },
        yaxis: {
          title: {
            text: "Growth",
            style: {
              color: "#adb5be",
              fontSize: "14px",
              fontFamily: "poppins, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-label",
            },
          },
          labels: {
            formatter: function (y: any) {
              return y.toFixed(0) + "";
            },
          },
        },
        fill: {
          opacity: 1
        },

      },


    };
  }



  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    )
  }
}

export class Viewers3 extends React.Component<{}, { options: any, series: any, }> {
  constructor(props: any) {
    super(props);
    this.state = {
      series: [
        {
          name: "Male",
          data: [44, 42, 57, 86, 58, 55, 70],
          color: "#38cab3",
        },
        {
          name: "Female",
          data: [34, 22, 47, 56, 21, 35, 60],
          color: "#ebeff5",
        },
      ],
      options: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          height: 330,
        },
        grid: {
          borderColor: "#eff2f6",
        },
        colors: ['var(--primary-bg-color)' || "#38cab3", "#e4e7ed"],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
          show: true,
          position: "top",
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },

        yaxis: {
          title: {
            style: {
              color: "	#adb5be",
              fontSize: "14px",
              fontFamily: "poppins, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-label",
            },
          },
          labels: {
            formatter: function (y: any) {
              return y.toFixed(0) + "";
            },
          },
        },
        xaxis: {
          categories: ["Mon", "Tue", "Web", "Thu", "Fri", "Sat", "Sun"],
          axisBorder: {
            show: true,
            color: "rgba(119, 119, 142, 0.05)",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "rgba(119, 119, 142, 0.05)",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        fill: {
          opacity: 1,
        },

      }
    }
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
      </div>
    );
  }
}

export const COLUMNS: any = [
  {
    Header: (<div className="position-relative">
      <Form.Label className="ckbox position-absolute" style={{ top: -7, left: -5 }}>
        <Form.Control type="checkbox" />
        <span className="tx-13"></span>
      </Form.Label>
      <p className="mb-0">Photo</p>
    </div>),
    accessor: "Photo",
    className: "text-center",
  },
  {
    Header: "Name",
    accessor: "Name",
    className: "text-center ",
  },
  {
    Header: "Location",
    accessor: "Location",
    className: "text-center ",
  },
  {
    Header: "Email",
    accessor: "Email",
    className: "text-center ",
  },
  {
    Header: "Phone Number",
    accessor: "PhoneNumber",
    className: "text-center ",
  },
  {
    Header: "Other Photos",
    accessor: "OtherPhotos",
    className: "text-center ",
  },
  {
    Header: "Level",
    accessor: "Level",
    className: "text-center ",
  },
];

export const DATATABLE = [
  {
    Photo: (
      <div className="position-relative">
        <Form.Label className="ckbox position-absolute">
          <Form.Control type="checkbox" />
          <span className="tx-13"></span>
        </Form.Label>
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOFYJhbdZJV5Aiz_rbdYbrgNUCD1S7rNrYT6WkV=w114-h114-n-k-no"}
          className="rounded-circle table-small-img"
          alt=""
        />
      </div>
    ),
    Name: "Airi Ramen Baytown",
    Location: "6300 Garth Rd ste 330, Baytown, TX 77521, United States",
    PhoneNumber: "+1 281-628-7252",
    Email: "test@gmail.com",
    OtherPhotos: (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOFYJhbdZJV5Aiz_rbdYbrgNUCD1S7rNrYT6WkV=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOFYJhbdZJV5Aiz_rbdYbrgNUCD1S7rNrYT6WkV=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOFYJhbdZJV5Aiz_rbdYbrgNUCD1S7rNrYT6WkV=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOFYJhbdZJV5Aiz_rbdYbrgNUCD1S7rNrYT6WkV=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />

      </div>
    ),
    Level: <span className="badge badge-success">Personalized</span>,
    color: "badge badge-success",

  },
  {
    Photo: (
      <div className="position-relative">
        <Form.Label className="ckbox position-absolute">
          <Form.Control type="checkbox" />
          <span className="tx-13"></span>
        </Form.Label>
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipNJy5eIPKoYgFGtkQc0z7Z9iPEB8DbIYlCux7cC=w114-h114-n-k-no"}
          className="rounded-circle table-small-img"
          alt=""
        />
      </div>
    ),
    Name: "Hillstone Restaurant",
    Location: "4848 Kirby Dr, Houston, TX 77098, United States",
    PhoneNumber: "+1 713-529-2385",
    Email: "test@gmail.com",
    OtherPhotos: (

      <div className="w-100 d-flex align-items-center justify-content-between">
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipNJy5eIPKoYgFGtkQc0z7Z9iPEB8DbIYlCux7cC=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipNJy5eIPKoYgFGtkQc0z7Z9iPEB8DbIYlCux7cC=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipNJy5eIPKoYgFGtkQc0z7Z9iPEB8DbIYlCux7cC=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipNJy5eIPKoYgFGtkQc0z7Z9iPEB8DbIYlCux7cC=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />

      </div>
    ),
    Level: <span className="badge badge-primary">Common</span>,

  },
  {
    Photo: (
      <div className="position-relative">
        <Form.Label className="ckbox position-absolute">
          <Form.Control type="checkbox" />
          <span className="tx-13"></span>
        </Form.Label>
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOoUsu8J_SM2skYJEGcyDV9N3-uGpD5K8xL1zIc=w114-h114-n-k-no"}
          className="rounded-circle table-small-img"
          alt=""
        />
      </div>
    )
    ,
    Name: "Vic & Anthony's Steakhouse",
    Location: "1510 Texas Ave, Houston, TX 77002, United States",
    PhoneNumber: "+1 713-228-1111",
    Email: "test@gmail.com",
    OtherPhotos: (

      <div className="w-100 d-flex align-items-center justify-content-between">
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOoUsu8J_SM2skYJEGcyDV9N3-uGpD5K8xL1zIc=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOoUsu8J_SM2skYJEGcyDV9N3-uGpD5K8xL1zIc=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOoUsu8J_SM2skYJEGcyDV9N3-uGpD5K8xL1zIc=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipOoUsu8J_SM2skYJEGcyDV9N3-uGpD5K8xL1zIc=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />

      </div>
    ),
    Level: <span className="badge badge-orange">Tone</span>,
  },
  {
    Photo: (
      <div className="position-relative">
        <Form.Label className="ckbox position-absolute">
          <Form.Control type="checkbox" />
          <span className="tx-13"></span>
        </Form.Label>
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipPvccozZYwe4x3LOgnFjBp1C653ZYXw2Cfcq2zZ=w114-h114-n-k-no"}
          className="rounded-circle table-small-img"
          alt=""
        />
      </div>
    ),
    Name: "Guard and Grace",
    Location: "500 Dallas St Suite 100, Houston, TX 77002, United States",
    PhoneNumber: "+1 346-326-0789",
    Email: "test@gmail.com",
    OtherPhotos: (

      <div className="w-100 d-flex align-items-center justify-content-between">
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipPvccozZYwe4x3LOgnFjBp1C653ZYXw2Cfcq2zZ=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipPvccozZYwe4x3LOgnFjBp1C653ZYXw2Cfcq2zZ=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipPvccozZYwe4x3LOgnFjBp1C653ZYXw2Cfcq2zZ=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />
        <img
          src={"https://lh5.googleusercontent.com/p/AF1QipPvccozZYwe4x3LOgnFjBp1C653ZYXw2Cfcq2zZ=w114-h114-n-k-no"}
          className="table-grid-img"
          alt=""
        />

      </div>
    ),
    Level: <span className="badge badge-primary">Common</span>,

  },

];
export const GlobalFilter = ({ filter, setFilter }: any) => {
  return (
    <span className="d-flex ms-auto">
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        className="form-control mb-4"
        placeholder="Search..."
      />
    </span>
  );
};