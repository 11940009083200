import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "./Firebase/firebase";

let firebaseApp: FirebaseApp;

if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  // console.log("get apps length", getApps());
  firebaseApp = getApps()[0];
}

let db = initializeFirestore(firebaseApp, {experimentalForceLongPolling: true, ignoreUndefinedProperties: true})


const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();
const functions = getFunctions(firebaseApp);

export {db, auth, functions, firebaseApp};
