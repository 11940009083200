import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {updateStep} from "../../../redux/account/accountStepSlice";
import { setName, setJobTitle, setLinkedinId, setSalesProfessionalEmail, setSalesProfessionalPhoneNumber, setUPS } from '../../../redux/active-item/active-itemSlice';
import { Form, FormGroup } from 'react-bootstrap';
import { RootState } from '../../../redux/store/store';

function Profile() {
    const dispatch = useDispatch();
    const salesProfessionalInfo = useSelector((state: RootState) => state.activeItem.activeUser.salesProfessionalInfo);
    return (
        <section className="card-body p-4">
            <Form id="Account Info">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        onChange={(e) => { dispatch(setName(e?.target?.value)); }}
                        value={salesProfessionalInfo?.name}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Job Title *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Job Title"
                        onChange={(e) => { dispatch(setJobTitle(e?.target?.value)) }}
                        value={salesProfessionalInfo?.jobTitle}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Email *
                    </Form.Label>
                    <Form.Control
                        type="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        onChange={(e) => { dispatch(setSalesProfessionalEmail(e?.target?.value)) }}
                        value={salesProfessionalInfo?.email}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Phone Number
                    </Form.Label>

                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Enter your phone number"
                        onChange={(e) => { dispatch(setSalesProfessionalPhoneNumber(e?.target?.value)) }}
                        value={salesProfessionalInfo?.phoneNumber}

                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        LinkedIn
                    </Form.Label>

                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Enter your LinkedIn profile URL"
                        onChange={(e) => { dispatch(setLinkedinId(e?.target?.value)) }}
                        value={salesProfessionalInfo?.linkedinId}

                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Personal Unique Selling Points (USPs) or areas of expertise *
                    </Form.Label>
                    <div className="mt-3">
                        <textarea
                            className="form-control"
                            placeholder="Enter Personal Unique Selling Points (USPs)"
                            rows={5}
                            onChange={(e) => { dispatch(setUPS(e?.target?.value)) }}
                            value={salesProfessionalInfo?.UPS}

                        ></textarea>

                    </div>
                </FormGroup>

                {/* <div className="py-3 d-flex justify-content-end">
                    <button
                        className={"btn btn-primary"}
                        onClick={(e: any) => { dispatch(updateStep({ step: 1, title: 'Competitor Information' })) }}
                    >
                        Next
                    </button>
                </div> */}
            </Form>
        </section>

    )
}

export default Profile