import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Dropdown, Form, FormGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/store';
import { updateStep } from '../../../../redux/active-product/currentStepSlice';
import { updateProductSuccessStories } from '../../../../redux/active-item/active-itemSlice';
import { ProductTestimonial } from '../../../../models/product';


export default function SucessStories() {
    const dispatch = useDispatch();

    const successStories = useSelector((state: RootState) => state.activeItem.activeProduct.successStories);
    const [successStoryTitle, setsuccessStoryTitle] = useState<string>('');
    const [successStoryCustomerType, setsuccessStoryCustomerType] = useState<string>('');
    const [successStoryDescription, setsuccessStoryDescription] = useState<string>('');
    const [successStoryRating, setsuccessStoryRating] = useState<number>(0);
    const [successStoryDate, setsuccessStoryDate] = useState<string>('');
    const [successStoryLink, setsuccessStoryLink] = useState<string>('');
    const [successStoryNError, setsuccessStoryNError] = useState<string>("");
    const [successStoryCTError, setsuccessStoryCTError] = useState<string>("");
    const [successStoryDError, setsuccessStoryDError] = useState<string>("");

    const handlesuccessStories = async () => {
        if (successStoryTitle && successStoryDescription && successStoryCustomerType) {
            setsuccessStoryDError("");
            setsuccessStoryCTError("");
            setsuccessStoryNError("");
            let successStory: ProductTestimonial = {
                name: successStoryTitle,
                description: successStoryDescription,
                customerType: successStoryCustomerType,
                date: successStoryDate,
                rating: successStoryRating,
                link: successStoryLink
            }
            dispatch(updateProductSuccessStories([...successStories?.filter(i => i != successStory), successStory]));
            setsuccessStoryTitle("");
            setsuccessStoryCustomerType("");
            setsuccessStoryDate("");
            setsuccessStoryDescription("");
            setsuccessStoryRating(0);
            setsuccessStoryLink("");
        } else {
            if (!successStoryTitle) {
                setsuccessStoryNError("Title is a required field");
            }
            if (!successStoryDescription) {
                setsuccessStoryDError("Description is a required field");
            }
            if (!successStoryCustomerType) {
                setsuccessStoryCTError("Customer Type is a required field");
            }

        }
    };

    const removeStory = (story: ProductTestimonial) => {
        let filteredStories = successStories?.filter(
            (value) =>
                value != story
        );
        if (filteredStories) {
            dispatch(updateProductSuccessStories(filteredStories));
        }
    };


    const handleBasicInfo = () => {
        dispatch(updateStep({ title: 'successStorys and social proof', step: 5 }));
    }
    return (
        <section className="card-body">
            <Form onSubmit={() => handleBasicInfo()} id="Success stories">
                <FormGroup className="control-group form-group">
                    {successStories?.length > 0 && <Form.Label className="form-label">
                        Success Stories
                    </Form.Label>}
                    <div className='mt-2'>
                        {successStories?.map((value, index) => {
                            return <Card className="mg-b-20 border">
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                {value?.name}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            setsuccessStoryTitle(value?.name);
                                                            setsuccessStoryDescription(value?.description);
                                                            setsuccessStoryDate(value?.date);
                                                            setsuccessStoryCustomerType(value?.customerType);
                                                            setsuccessStoryRating(value?.rating);
                                                            setsuccessStoryLink(value?.link ?? "");
                                                            removeStory(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removeStory(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value?.description}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Name
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={successStoryTitle}
                            onChange={(e) => setsuccessStoryTitle(e?.currentTarget?.value)}
                        />
                        <p className={"text-error"}>
                            {successStoryNError}
                        </p>
                    </FormGroup>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Customer Type
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={successStoryCustomerType}
                            onChange={(e) => setsuccessStoryCustomerType(e?.currentTarget?.value)}
                        />
                        <p className={"text-error"}>
                            {successStoryCTError}
                        </p>
                    </FormGroup>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Rating
                        </Form.Label>
                        <Form.Control
                            type="number"
                            className="form-control"
                            placeholder=""
                            min={0}
                            max={5}
                            step={1}
                            value={successStoryRating}
                            onChange={(e) => {
                                if (parseFloat(e?.currentTarget?.value) >= 5) {
                                    setsuccessStoryRating(5)
                                } else if (parseFloat(e?.currentTarget?.value) <= 0) {
                                    setsuccessStoryRating(0)
                                } else {
                                    setsuccessStoryRating(parseFloat(e?.currentTarget?.value))
                                }
                            }}
                        />
                        {/* <p className={"text-error"}>
                            {successStoryNError}
                        </p> */}
                    </FormGroup>
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Date
                        </Form.Label>
                        <Form.Control
                            type="date"
                            className="form-control"
                            placeholder=""
                            value={successStoryDate}
                            onChange={(e) => setsuccessStoryDate(e?.currentTarget?.value)}
                        />
                        {/* <p className={"text-error"}>
                            {successStoryNError}
                        </p> */}
                    </FormGroup>
                    <textarea
                        className="form-control"
                        placeholder="Description"
                        rows={5}
                        value={successStoryDescription}
                        onChange={(e) => {
                            setsuccessStoryDescription(e?.target?.value);
                        }}
                    ></textarea>
                    {successStoryDError && (
                        <p className={"text-error"}>
                            {successStoryDError}
                        </p>
                    )}
                    <FormGroup className="control-group form-group">
                        <Form.Label className="form-label">
                            Link
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={successStoryLink}
                            onChange={(e) => setsuccessStoryLink(e?.currentTarget?.value)}
                        />
                    </FormGroup>
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handlesuccessStories}
                    >
                        Add
                    </Button>
                </FormGroup>
            </Form>

        </section>
    );
}