import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Dropdown, Form, FormGroup, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ProductBasicInfo, HighlightedFeatures } from "../../../../redux/active-product/model";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../../redux/store/store';
import JoditEditor from "jodit-react";
import { updateStep } from '../../../../redux/active-product/currentStepSlice';
import { Link } from 'react-router-dom';
import { updateProductBasicInfo } from '../../../../redux/active-item/active-itemSlice';


export default function BasicInfo() {
    const schema = yup
        .object({
            businessName: yup.string().required("Business name is a required field"),
            productName: yup.string().required("Product name is a required field"),
            description: yup.string().required("Description is a required field"),
        })
        .required();
    const basicInfo = useSelector((state: RootState) => state.activeItem.activeProduct.basicInfo);

    const { register, control, handleSubmit, watch, reset, setValue, formState: { isDirty, errors, isValid } } =
        useForm<ProductBasicInfo>({ mode: "all", resolver: yupResolver(schema), defaultValues: basicInfo });

    const businessName = watch("businessName");
    const description = watch("description");
    const productName = watch("productName");
    const sku = watch("sku");

    useEffect(() => {
        if (businessName !== basicInfo?.businessName || description !== basicInfo?.description || productName !== basicInfo?.productName || sku !== basicInfo?.sku) {
            dispatch(updateProductBasicInfo({
                businessName: businessName ?? '',
                productName: productName ?? '',
                description: description ?? '',
                sku: sku ?? ''
            }))
        }
    }, [businessName, description, productName, sku])


    useEffect(() => {
        //for some reason businessName keeps oscillating. Troubleshoot. businessName !== basicInfo.businessName || 
        if (basicInfo && (description !== basicInfo.description || productName !== basicInfo.productName || sku !== basicInfo.sku)) {
            const { businessName, description, productName, sku } = basicInfo;
            reset({ businessName, description, productName, sku });
        }
    }, [basicInfo, reset]);
    const dispatch = useDispatch();
    const company = useSelector((state: RootState) => state.activeItem.activeCompany.basicCompanyInfo);

    useEffect(() => {
        if (company?.name) {
            setValue('businessName', company?.name)
        }
    }, [company])

    const handleBasicInfo = (data: any) => {
        dispatch(updateStep({ title: 'Features and benefits', step: 2 }));
    }
    return (
        <section className="card-body">
            <Form onSubmit={handleSubmit(handleBasicInfo)} id="Product descriptions">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Your business Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Business Name"
                        {...register("businessName")}
                    />
                    <p className={"text-error"}>
                        {errors?.businessName?.message}
                    </p>
                </FormGroup>
                {/* <FormGroup className="control-group form-group">
                    <Form.Label>
                        Product SKU *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Product SKU"
                        {...register("sku")}
                    />
                    <p className={"text-error"}>
                        {errors?.sku?.message}
                    </p>
                </FormGroup> */}
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Product Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Product Name"
                        {...register("productName")}
                    />
                    <p className={"text-error"}>
                        {errors?.productName?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Describe your product in a few words
                    </Form.Label>
                    <Controller
                        render={({ field }) => (
                            <JoditEditor
                                {...field}
                            />
                        )}
                        control={control}
                        name="description"
                    />
                    <p className={"text-error"}>
                        {errors?.description?.message}
                    </p>
                </FormGroup>
            </Form>

        </section>
    );
}
