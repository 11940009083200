export const FIRESTORE = {
    CAMPAIGN: "campaign",
    CAMPAIGN_TEMPLATE: "campaign-template",
    COMPANY: "company",
    PRODUCT: "product",
    PROMPT: "prompt",
    PROMPT_TEST_DATA: "zz-admin/prompt/test-data",
    USER:"user",
    LEADS: "leads",
    COPY: "copy",
}