import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Dropdown, Form, FormGroup, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Company, Competitor } from '../../../../models/company';
import { RootState } from '../../../../redux/store/store';
import { addCompanyCompetitor, removeActiveCompany, removeCompanyCompetitor } from '../../../../redux/active-item/active-itemSlice';
import useCompanies from '../../../../hooks/useCompanies';
import slug from 'slug';
import Swal from 'sweetalert2';
import { auth } from '../../../../firebaseutil';

function CompetitorInfo({ company, id }: { company: Company; id?: string }) {
    const dispatch = useDispatch();
    const { saveCompany, loading } = useCompanies();
    const navigate = useNavigate();
    const competitors = useSelector((state: RootState) => state.activeItem.activeCompany.competitorsInfo);
    const basicInfo = useSelector((state: RootState) => state.activeItem.activeCompany.basicCompanyInfo);
    const [name, setName] = useState<string>('');
    const [advantage, setAdvantage] = useState<string>('');
    const [disadvantage, setDisadvantage] = useState<string>('');

    const save = async () => {
        if (auth?.currentUser?.uid) {
            let companyId = id === "new" ? slug(basicInfo?.name) : id ?? "new";
            await saveCompany({
                ...basicInfo,
                competitors: competitors,
                id: companyId,
                clientId: company?.clientId ?? companyId,
                createdAt: company?.createdAt ?? new Date().toISOString(),
                lastUpdated: new Date().toISOString(),
                userId: auth?.currentUser?.uid
            });
            if (id === "new") {
                const result = await Swal.fire({
                    title: "Confirmation",
                    text: "New Company Added Successfully.",
                    allowOutsideClick: true,
                    confirmButtonText: "Okay",
                    cancelButtonColor: "#38cab3",
                });
                if (result?.isConfirmed) {
                    dispatch(removeActiveCompany(""));

                    navigate(`/pages/company/${companyId}`);
                }
            } else {
                const result = await Swal.fire({
                    title: "Confirmation",
                    text: "Updated Sucessfully.",
                    allowOutsideClick: true,
                    confirmButtonText: "Okay",
                    cancelButtonColor: "#38cab3",
                });
            }
        }
    }

    const handleCompetitor = () => {
        let competitor: Competitor = {
            name,
            advantage,
            disadvantage
        }
        dispatch(addCompanyCompetitor(competitor));
        setName('');
        setAdvantage('');
        setDisadvantage('');
        window.scrollTo(0, window?.innerHeight + 100)
    }
    return (
        <section className="card-body p-4" id="Competitor Information">
            <Form id="Company Info">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Competitor Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        onChange={(e) => setName(e?.target?.value)}
                        value={name}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Advantages
                    </Form.Label>
                    <div className="mt-3">
                        <textarea
                            className="form-control"
                            placeholder="Enter advantages (e.g., competitive pricing, innovative products, excellent customer service)"
                            rows={5}
                            onChange={(e) => setAdvantage(e?.target?.value)}
                            value={advantage}
                        ></textarea>

                    </div>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Disadvantages
                    </Form.Label>
                    <div className="mt-3">
                        <textarea
                            className="form-control"
                            placeholder="Enter a disadvantage (optional)"
                            rows={5}
                            onChange={(e) => setDisadvantage(e?.target?.value)}
                            value={disadvantage}
                        ></textarea>

                    </div>
                </FormGroup>
                <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleCompetitor}
                >
                    Add
                </Button>
            </Form>
            {competitors?.length > 0 && <div className='mt-5'>
                <Form.Label className="form-label">
                    Competitor List
                </Form.Label>
                {
                    competitors?.map(value => (
                        <Card className="text-dark mg-b-20 border" key={value.name}>
                            <Card.Header className=" p-4">
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mb-0 mg-t-3">
                                            {value?.name}
                                        </h6>
                                        <span className="text-muted">
                                            {/* Sep 26 2019, 10:14am */}
                                        </span>
                                    </div>
                                    <div className="ms-auto">
                                        <Dropdown className=" show main-contact-star">
                                            <Dropdown.Toggle
                                                variant=""
                                                className="new option-dots2"
                                                data-bs-toggle="dropdown"

                                            >
                                                <i className="fe fe-more-vertical  tx-18"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu shadow">
                                                {" "}
                                                <Dropdown.Item
                                                    className="dropdown-item"
                                                    href="#"
                                                    onClick={() => {
                                                        setName(value?.name);
                                                        setAdvantage(value?.advantage);
                                                        setDisadvantage(value?.disadvantage);
                                                        dispatch(removeCompanyCompetitor(value));
                                                    }}
                                                >
                                                    Edit
                                                </Dropdown.Item>{" "}
                                                <Dropdown.Item
                                                    className="dropdown-item"
                                                    href="#"
                                                    onClick={() => dispatch(removeCompanyCompetitor(value))}
                                                >
                                                    Delete
                                                </Dropdown.Item>{" "}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className=" h-100">
                                <div>
                                    <p className='fw-bold mb-2'>Advantage</p>
                                    <p className="mg-t-0">
                                        {value?.advantage}
                                    </p>
                                </div>
                                <div>
                                    <p className='fw-bold mb-2'>Disadvantage</p>
                                    <p className="mg-t-0">
                                        {value?.disadvantage}
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                }
            </div>}
            {loading ?
                <div className="text-center">
                    <div className="lds-dual-ring"></div>
                </div> :
                <div className="py-3 d-flex justify-content-between">
                    <div></div>
                    <button
                        className={"btn btn-primary"}
                        onClick={(e: any) => { save() }}
                    >
                        Save
                    </button>
                </div>}
        </section>

    )
}

export default CompetitorInfo