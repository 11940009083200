import { doc, setDoc, getDoc } from "firebase/firestore";
import { Profile } from "../models/profile"
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { useEffect, useState } from "react";
import { db } from '../firebaseutil';
import { setSalesProfessionalInfo, setCommunicationPreferences, setGoalsAndObjectives } from "../redux/active-item/active-itemSlice";
import { useDispatch } from "react-redux";

const rootCollection = FIRESTORE.USER;

export default function useProfile() {
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const [profile, setProfile] = useState<Profile>();
    const saveProfile = async (profile: Profile) => {
        const profileRef = await doc(firestore, rootCollection, profile.id);
        await setDoc(profileRef, profile, { merge: true });
        console.log('Profile saved!')
    }
    const fetchProfile = async (userId: string): Promise<Profile> => {
        const ref = doc(db, `${rootCollection}/${userId}`);

        const profile = (await getDoc(ref)).data() as Profile;
        console.log({ profile })
        setProfile(profile)
        return profile;
    };

    useEffect(() => {
        if (profile) {
            dispatch(setSalesProfessionalInfo({
                name: profile?.name,
                email: profile?.email,
                phoneNumber: profile?.phoneNumber,
                jobTitle: profile?.jobTitle,
                linkedinId: profile?.linkedinId,
                UPS: profile?.UPS
            }));
            dispatch(setGoalsAndObjectives({
                cycleTime: profile?.cycleTime ?? "",
                leads: profile?.leads,
                winRate: profile?.winRate ?? "",
                revenue: profile?.revenue ?? "",
                profitMargin: profile?.profitMargin ?? "",
                churnRate: profile?.churnRate ?? "",
                upSell: profile?.upSell ?? ""
            }));
            dispatch(setCommunicationPreferences({
                email: profile?.email,
                phoneNumber: profile?.phoneNumber,
                toneAndStyle: profile?.toneAndStyle,
                socialMediaHandle: profile?.socialMediaHandle
            }))
        }
    }, [profile])
    return { saveProfile, fetchProfile, profile }
}