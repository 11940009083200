import { Outlet } from "react-router-dom";
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { AuthProvider, useFirebaseApp } from 'reactfire';


const Auth = () => {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  return (
    <AuthProvider sdk={auth}>
      <div >
        <Outlet/>      
      </div>
    </AuthProvider>
  );
};

export default Auth;
