import React, { useContext, useEffect, useState } from "react";
import { Col, Pagination, Row, Card, Button, Modal, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addToCart, removeFromCart } from '../../../redux/cart/cartSlice';
import ProductService from "../../../services/ProductService";
import { Vertical } from "../../Forms/FormWizard/Verticalwizard";
import { shorten } from "../../../util/util";
import useProducts from "../../../hooks/useProducts";
import xss from "xss";
import { Product } from "../../../models/product";

const ProductsList = () => {
  const { products } = useProducts();
  const [list, setList] = React.useState<any>({
    InitialList: ProductService.getProductList()
  });
  const [success, setSuccess] = useState(false);
  function handleRemove(id: any) {
    const newList = list.filter((list: any) => list.id !== id);
    setList(newList);
  }
  let { InitialList } = list;
  const dispatch = useDispatch();
  const send = (e: any) => {
    // console.log(e);
    dispatch(addToCart(e));
  }
  const ondelete = (item: any) => {
    dispatch(removeFromCart(item))
  }
  let [fullScreen, setFullscreen] = useState("true");
  const [show, setShow] = useState(false);

  const fullscreenmodalClose = () => setSuccess(true);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  const { cloneProduct } = useProducts();
  const clone = async (product: Product) => {
    await cloneProduct(product);
  }
  return (
    <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">PRODUCT LIST</span>
        </div>
        <Button variant=""
          className="btn btn-md btn-primary mb-2 ms-2 w-45 "
        >
          <Link className="btn ripple btn-primary" to="/pages/product/new"><i className="fe fe-plus me-2"></i>Add New Product</Link>
        </Button>
        <Modal show={show} fullscreen={fullScreen} onHide={() => setShow(false)}>
          <Modal.Header>
            <Modal.Title>Create New Product</Modal.Title>
            <span className="d-flex ms-auto" onClick={fullscreenmodalClose}><i className='fe fe-x ms-auto' ></i></span>
          </Modal.Header>
          <Modal.Body>

            <Row>
              <Col lg={12} md={12}>
                <Card>
                  <Card.Body>
                    <div id="wizard3">
                      <Vertical />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fullscreenmodalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={fullscreenmodalClose}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={success} >
          <Modal.Header>
            <Button variant="" className="btn btn-close" onClick={() => { setSuccess(false); setShow(false) }}>
              x
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="tx-center ">
              <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block"></i>{" "}
              <h4 className="tx-success tx-semibold mg-b-20">Congratulations!</h4>{" "}
              <p className="mg-b-20 mg-x-20">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration.
              </p>
              <Button
                variant=""
                aria-label="Close"
                className="btn ripple btn-success pd-x-25"
                type="button"
                onClick={() => { setSuccess(false); setShow(false) }}
              >
                Continue
              </Button>{" "}
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {/* <!-- /breadcrumb --> */}

      {/* <!-- ROW-1 OPEN --> */}
      <Col className="p-0" lg={12} xl={12}>
        <Row className="row product-container">
          {products?.map((item) => (
            <Col xl={3} lg={6} md={4} className="alert" key={item.id}>

              <Card className=" item-card position-relative">
                <Card.Header className="position-absolute d-flex justify-content-end align-items-center p-0" style={{ right: 0 }}>
                  <div className="ms-auto">
                    <Dropdown className=" show main-contact-star">
                      <Dropdown.Toggle
                        variant=""
                        className="new option-dots2"
                        data-bs-toggle="dropdown"

                      >
                        <i className="fe fe-more-vertical  tx-18"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu shadow">
                        {" "}
                        <Dropdown.Item
                          className="dropdown-item"
                          href={`${process.env.PUBLIC_URL}/pages/product/${item.id}`}
                        >
                          Open
                        </Dropdown.Item>{" "}
                        <Dropdown.Item
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            clone(item)
                          }}
                        >
                          Clone
                        </Dropdown.Item>{" "}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Link to={`${process.env.PUBLIC_URL}/pages/product/${item.id}`}>
                  <Card.Body className="pb-0">
                    <div className="text-center zoom profile-image">
                      <img className="br-5 img-fluid" src={item.image} alt="img" />
                    </div>
                    <Card.Body className=" px-0 pb-3">
                      <Row>
                        <div className="col-12">
                          <div className="cardtitle">
                            {shorten(item.productName, 70)}
                          </div>
                        </div>
                        <div>
                          <p className="shop-description fs-13 text-muted mt-2">
                            <div dangerouslySetInnerHTML={{ __html: xss(item.description) }} style={{ height: 80, overflow: 'clip' }}>

                            </div>
                          </p>
                        </div>
                      </Row>
                    </Card.Body>
                  </Card.Body>
                </Link>
                <Card.Footer className=" text-center">
                  <div className="text-center ps-2 pe-2">
                    {/* <Button variant=""
                      className="btn btn-md btn-primary mb-2 ms-2 w-45 "
                      onClick={() => send(item)}
                    >
                      <Link to={`${process.env.PUBLIC_URL}/pages/e-commerce/cart`} className="text-white">
                        <i className="fe fe-shopping-cart me-2"></i>{' '}Add to Cart
                      </Link>
                    </Button> */}
                    {/* <Link
                        to=""
                        className="btn btn-md btn-light mb-2 ms-2 w-45"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => { ondelete(item); handleRemove(item.id) }}
                      >
                        <span className="me-2 fs-14">Remove</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16px"
                          viewBox="0 0 24 24"
                          width="16px"
                          fill="#495057"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                        </svg>
                      </Link> */}
                  </div>
                </Card.Footer>
              </Card>

            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-end">
          <Pagination className="pagination mb-5">
            <Pagination.Item className="disabled page-item">‹</Pagination.Item>
            <Pagination.Item className="active page-item">1</Pagination.Item>
            <Pagination.Item className="page-item">2</Pagination.Item>
            <Pagination.Item className="page-item">3</Pagination.Item>
            <Pagination.Item className="page-item">4</Pagination.Item>
            <Pagination.Item className="page-item">5</Pagination.Item>
            <Pagination.Item className="page-item">›</Pagination.Item>
          </Pagination>
        </div>
      </Col>
      {/* <!-- COL-END --> */}
    </div>
  );
}

ProductsList.propTypes = {};

ProductsList.defaultProps = {};

export default ProductsList;
