import React, { useState } from "react";
import { Card, Col, Dropdown, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCompanies from "../../../../hooks/useCompanies";
import Swal from "sweetalert2";
function CompanyList() {

    const {companies, deleteCompany} = useCompanies();

    const deleteC = async(id: string) => {
        const result = await Swal.fire({
            title: "Confirmation",
            text: "Are you sure, you want to delete this company, this is an irreversible action?",
            allowOutsideClick: true,
            confirmButtonText: "Yes",
            cancelButtonColor: "#38cab3",
            cancelButtonText: "No",
            showCancelButton: true,
          });
          if(result?.isConfirmed){
            await deleteCompany(id);
          }      
    }
    
    return (
        <div>
            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">Company List</span>
                </div>

                <Button variant=""
                    className="btn btn-md btn-primary mb-2 ms-2 w-45 "
                >
                    <Link className="btn ripple btn-primary" to="/pages/company/new"><i className="fe fe-plus me-2"></i>Create Company</Link>
                </Button>
            </div>
            {/* <!-- /breadcrumb --> */}

            {companies?.map((company, index) => (
                <Row key={index}>
                    <Col lg={12} md={12}>
                        <div
                            className="main-content-body  tab-pane border-top-0"
                            id="timeline"
                        >
                            <div className="border-0">
                                <div className="main-content-body main-content-body-profile">
                                    <div className="main-profile-body p-0">
                                        <Link to={`${process.env.PUBLIC_URL}/pages/company/${company?.id}`}>
                                            <Row className=" row-sm">
                                                <div className="col-12">
                                                    <Card className=" mg-b-20 border">
                                                        <Card.Header className="px-4 py-2">
                                                            <div className="media">
                                                                <div className="media-body">
                                                                    <h6 className="mb-0 mg-t-2">
                                                                        {company?.name}
                                                                    </h6>
                                                                </div>
                                                                <div className="ms-auto" onClick={(e) => {e?.preventDefault()}}>
                                                                    <Dropdown className=" show main-contact-star">
                                                                        <Dropdown.Toggle
                                                                            variant=""
                                                                            className="new option-dots2"
                                                                            data-bs-toggle="dropdown"

                                                                        >
                                                                            <i className="fe fe-more-vertical  tx-18"></i>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu shadow">
                                                                            {" "}
                                                                            <Dropdown.Item
                                                                                className="dropdown-item"
                                                                                href="#"
                                                                            >
                                                                                <Link to={`${process.env.PUBLIC_URL}/pages/company/${company?.id}`}>Edit</Link>
                                                                            </Dropdown.Item>{" "}
                                                                            <Dropdown.Item
                                                                                className="dropdown-item"
                                                                                href="#"
                                                                                onClick={() => deleteC(company?.id)}
                                                                            >
                                                                                Delete
                                                                            </Dropdown.Item>{" "}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </Card.Header>
                                                        <div className="card-body py-0">
                                                            <p className="mg-t-0">
                                                                There are many variations of passages
                                                                of Lorem Ipsum available, but the
                                                                majority have suffered alteration in
                                                                some form, by injected humour, or
                                                                randomised words which don't look even
                                                                slightly believable.
                                                            </p>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Row>
                                        </Link>
                                    </div>
                                    {/* <!-- main-profile-body --> */}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ))}


        </div>
    );
}

CompanyList.propTypes = {};

CompanyList.defaultProps = {};

export default CompanyList;
