import { useEffect, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import slug from "slug";
import { Gallery } from "../models/product";
import { firebaseConfig } from "../Firebase/firebase";
import { auth, firebaseApp } from "../firebaseutil";
import {productUrl, uniqueId } from "../util/util";

const LISTING_BUCKET = firebaseConfig.listingBucket;
const storage = getStorage(firebaseApp, LISTING_BUCKET);

const FOLDER_PREFIX = moment().format("YYMMDD");

interface KV {
  key: string;
  value: string;
  deleted: boolean;
}

export default function useImageUpload() {
  const [uploadedfiles, setUploadedFiles] = useState<KV[]>([]);
  const [uploadedOriginalfiles, setUploadedOriginalFiles] = useState<KV[]>([]);

  useEffect(() => {
    if (!!!auth?.currentUser?.uid) {
      setUploadedFiles([]);
      setUploadedOriginalFiles([]);
    }
  }, [auth?.currentUser?.uid]);

  const slugFileName = (name:string) => {
    slug.charmap["."] = "-";
    const s = slug(name);
    slug.reset();
    return s;
  };
  const upload = async (file: File) => {
    const fileName = `${file.size}-${file.name}`;
    const parts = fileName.split(".");
    const extension = parts.pop(); //extension in name2
    const name = parts.join(".");

    //avoid duplicates
    if (uploadedfiles.map((f) => f.key).includes(name)) {
      setUploadedFiles((prev) => [
        ...prev.filter((f) => f.key != name),
        {
          key: name,
          value: prev?.find((p) => p.key === name)?.key ?? name,
          deleted: false,
        },
      ]);
      return;
    }

    const blobUrl = URL.createObjectURL(file);
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(
        storage,
        `${FOLDER_PREFIX}/${uniqueId()}/${new Date().getTime()}-${slugFileName(
          name
        )}.${extension}`
      );
      await uploadBytes(fileContainer, blob);
      const url = await getDownloadURL(fileContainer);
      //format: https://firebasestorage.googleapis.com/v0/b/fleato-listing/o/220304%2F1646434457004-58795-ba3ag-jpg?alt=media&token=fd0f053e-f03d-4070-8ce5-924ff6600f38
      let namePart = url.split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";
      const cdnUrl = productUrl(
        `${firebaseConfig.storageBaseUrl}/${namePart}`,
        680
      );
      //cdn format: https://cdn.fleato.com/220304%2F1646434457004-58795-ba3ag-jpg
      setUploadedOriginalFiles((prev) => [
        ...prev.filter((f) => f.key != name),
        {
          key: name,
          value: `${firebaseConfig.storageBaseUrl}/${namePart}`,
          deleted: false,
        },
      ]);
      setUploadedFiles((prev) => [
        ...prev.filter((f) => f.key != name),
        { key: name, value: cdnUrl, deleted: false },
      ]);
    } catch (err) {
      // console.log("Error uploading");
      throw err;
    }
  };

  const uploadVariationImages = async (file: File) => {
    const name = `${file.size}-${file.name}`;
    console.log("file = ", file?.type === "image" ? "image" : file?.type);

    // if(uploadedfiles.map(f => f.key).includes(name)) {
    //   setUploadedFiles(prev => [...prev.filter(f => f.key != name), {key: name, value: prev.find(p => p.key === name).key, deleted: false}]);
    //   return ;
    // }

    const blobUrl = URL.createObjectURL(file);
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(
        storage,
        `${FOLDER_PREFIX}/${
          firebaseConfig.defaultRandomUUID
        }/${new Date().getTime()}-${slugFileName(name)}`
      );
      await uploadBytes(fileContainer, blob);            
      const url = await getDownloadURL(fileContainer);
      let namePart = url.split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";      
      const cdnUrl = `${firebaseConfig.storageBaseUrl}/${namePart}`;      
      return url;
    } catch (err) {
      // console.log("Error uploading");
      throw err;
    }
  };
  const uploadResourceFiles = async (file: File) => {
    const name = `${file.size}-${file.name}`;

    const blobUrl = URL.createObjectURL(file);
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(
        storage,
        `${FOLDER_PREFIX}/${
          firebaseConfig.defaultRandomUUID
        }/${new Date().getTime()}-${slugFileName(name)}`
      );
      await uploadBytes(fileContainer, blob);
      const url = await getDownloadURL(fileContainer);
      let namePart = url.split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";
      const cdnUrl = `${firebaseConfig.storageBaseUrl}/${namePart}`;

      return cdnUrl;
    } catch (err) {
      console.log("Error uploading");
      throw err;
    }
  };

  const uploadCustomizationImages = async (file: File): Promise<Gallery> => {
    const name = `${file.size}-${file.name}`;

    // if(uploadedfiles.map(f => f.key).includes(name)) {
    //   setUploadedFiles(prev => [...prev.filter(f => f.key != name), {key: name, value: prev.find(p => p.key === name).key, deleted: false}]);
    //   return ;
    // }

    const blobUrl = URL.createObjectURL(file);
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(
        storage,
        `${FOLDER_PREFIX}/${
          firebaseConfig.defaultRandomUUID
        }/${new Date().getTime()}-${slugFileName(name)}`
      );
      await uploadBytes(fileContainer, blob);
      const url = await getDownloadURL(fileContainer);
      let namePart = url.split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";
      const cdnUrl = `${firebaseConfig.storageBaseUrl}/${namePart}`;
      return {
        url: cdnUrl,
        thumbnail: cdnUrl,
        type: file?.type.indexOf("video")
          ? "video"
          : file?.type.includes("image")
          ? "image"
          : "image",
      };
    } catch (err) {
      // console.log("Error uploading");
      throw err;
    }
  };

  const remove = async (file: File) => {
    //User dont have permission to remove items from storage. Just forget it
    const name = `${file.size}-${file.name}`;
    setUploadedFiles((prev:any) => [
      ...prev.filter((f:any) => f.key != name),
      {
        key: name,
        value: prev.find((p:any) => p.key === name)?.key,
        deleted: true,
      },
    ]);
    setUploadedOriginalFiles((prev:any) => [
      ...prev.filter((f:any) => f.key != name),
      {
        key: name,
        value: prev.find((p:any) => p.key === name)?.key,
        deleted: true,
      },
    ]);
  };

  const uploadBase64toStorage = async (
    blobUrl: string,
    folderPrefix: string,
    name: string,
    fileType: string
  ) => {
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(storage, `${folderPrefix}/${name}.${fileType}`);
      await uploadBytes(fileContainer, blob);
      const url = await getDownloadURL(fileContainer);
      let namePart = url.replaceAll("%2F", "/").split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";
      const cdnUrl = `${firebaseConfig.storageBaseUrl}/${folderPrefix}/${namePart}`;
      return { url: cdnUrl, thumbnail: cdnUrl, type: "image" };
    } catch (err) {
      // console.log("Error uploading");
      throw err;
    }
  };

  return {
    uploadedfiles: uploadedfiles.filter((f) => !f.deleted).map((f) => f.value),
    uploadedOriginalfiles: uploadedOriginalfiles
      .filter((f) => !f.deleted)
      .map((f) => f.value),
    upload,
    remove,
    uploadVariationImages,
    uploadCustomizationImages,
    uploadBase64toStorage,
    uploadResourceFiles,
  };
}
