import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Prompt } from '../../Firebase/models';
import { arraysEqual } from '../../util/util';

export interface currentStep{
  step: number;
  title:string;
}

export interface activePromptState {
    prompt: Prompt;
    folder: string[];
    wizardStep:currentStep;
}

const initialState: activePromptState = {
    prompt: {
      name: "",
      prompt: "Your task is to help a marketing team create a description for direct marketing of a product based on a technical fact sheet. Write an email to the prospective customer based on the information provided in the technical specifications delimited by triple backticks.",
      folder: ["/"],
      id: "",
      clientId: "multipliedai",
      outputFormat: "Format everything as HTML that can be used in a website. Place the description in a <div> element.",
      outputType: "html",
      substitutions: {}
    },
    folder: ["/"],
    wizardStep:{
      step:0,
      title:'Prompt'
    }
}

export const activePromptSlice = createSlice({
    name: "activePrompt",
    initialState,
    reducers: {
        selectPrompt: (state, action: PayloadAction<Prompt>) => {
          state.prompt = action.payload;
          state.folder = action.payload?.folder;
        },
        updatePrompt: (state, action: PayloadAction<Prompt>) => {
            state.prompt = action.payload;
            state.folder = action.payload?.folder;
        },
        save: (state) => {
        },
        selectFolder: (state, action: PayloadAction<string[]>) => {
            state.folder = action.payload;
            if(action.payload && !arraysEqual(state.prompt?.folder, action.payload))
                state.prompt = initialState.prompt;
        },
        updateStep: (state, action: PayloadAction<currentStep>) => {
          const {step,title }= action.payload;
          state.wizardStep.step=step;
          state.wizardStep.title=title;
        },
      }
})

// Action creators are generated for each case reducer function
export const {selectPrompt, updatePrompt, selectFolder,updateStep} = activePromptSlice.actions;
export default activePromptSlice.reducer;