import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { updateStep } from '../../../redux/account/accountStepSlice';
import { setEmail, setPhoneNumber, setSocialMediaHandle, setToneAndStyle } from '../../../redux/active-item/active-itemSlice';
import { Button, Card, Dropdown, Form, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import useProfile from '../../../hooks/useProfile';
import { useFirebaseApp } from 'reactfire';
import { SocialHandle } from '../../../models/profile';
import Swal from "sweetalert2";
import { RootState } from '../../../redux/store/store';

interface SocialMediaHandle {
    name: string;
    handle: string;
}
function Communication() {
    const dispatch = useDispatch();
    const [name, setName] = useState<string>('');
    const [handle, setHandle] = useState<string>('');
    const [showSave, setShowSave] = useState<boolean>(false);
    const [selectedSocialHandle, setSelectedSocialHandle] = useState<SocialHandle>();

    const app = useFirebaseApp();
    const auth = getAuth(app);
    const socialMediaHandle = useSelector((state: RootState) => state.activeItem.activeUser.communicationPreferences.socialMediaHandle);
    const salesProfessionalInfo = useSelector((state: RootState) => state.activeItem.activeUser.salesProfessionalInfo);
    const goalsAndObjectives = useSelector((state: RootState) => state.activeItem.activeUser.goalsAndObjectives);
    const communicationPreferences = useSelector((state: RootState) => state.activeItem.activeUser.communicationPreferences);

    const { saveProfile, fetchProfile, profile } = useProfile();
    useEffect(() => {
        fetchProfile(auth?.currentUser?.uid as string);
    }, [])
    console.log({ profile }, auth?.currentUser?.uid);
    const handleSelect = (eventKey: any, socialHandle: SocialMediaHandle) => {
        if (eventKey == 'edit') {
            handleEdit(socialHandle);
        }
        else if (eventKey == 'remove') {
            handleRemove(socialHandle);
        }
    };
    const handleEdit = (socialHandle: SocialMediaHandle) => {
        setSelectedSocialHandle(socialHandle);
        setName(socialHandle?.name);
        setHandle(socialHandle?.handle);
        setShowSave(true);
    }
    const saveEdit = (socialHandle: SocialMediaHandle) => {
        let filtered = socialMediaHandle?.filter((value: SocialMediaHandle) => value?.name != selectedSocialHandle?.name && value?.handle != selectedSocialHandle?.handle);
        dispatch(setSocialMediaHandle([...filtered, socialHandle]));
        setName('');
        setHandle('');
        setSelectedSocialHandle({ name: '', handle: '' });
        setShowSave(false);
    }
    const handleRemove = (socialHandle: SocialMediaHandle) => {
        let filtered = socialMediaHandle?.filter((value: SocialMediaHandle) => value?.name != socialHandle?.name && value?.handle != socialHandle?.handle);
        dispatch(setSocialMediaHandle([...filtered]));
    }
    const handleSocialMedia = () => {
        let localSocialMediaHandle = {
            name,
            handle
        };
        dispatch(setSocialMediaHandle([...socialMediaHandle, localSocialMediaHandle]));
        setName('');
        setHandle('');
    }
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const onSubmit = async () => {
        setIsBusy(true);
        let profile = {
            id: auth?.currentUser?.uid ?? "",
            ...salesProfessionalInfo,
            ...goalsAndObjectives,
            ...communicationPreferences,
        }
        const result = await saveProfile(profile);
        setIsBusy(false);
        if (typeof result === "string") {
            await Swal.fire({
                title: "Error !",
                text: result,
                allowOutsideClick: true,
                confirmButtonText: "Okay",
                cancelButtonColor: "#38cab3",
            });
        } else {
            const r = await Swal.fire({
                title: "Confirmation",
                text: `Profile Saved Successfully.`,
                allowOutsideClick: true,
                confirmButtonText: "Okay",
                cancelButtonColor: "#38cab3",
            });
        }
    }
    return (
        <section className="card-body p-4" id="Communication Preferences">
            <Form id="Communication Info">
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Email *
                    </Form.Label>
                    <Form.Control
                        type="email"
                        className="form-control"
                        placeholder="Enter your email address"
                        onChange={(e) => dispatch(setEmail(e?.target?.value))}
                        value={communicationPreferences?.email}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Phone Number
                    </Form.Label>

                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Enter your phone number"
                        onChange={(e) => dispatch(setPhoneNumber(e?.target?.value))}
                        value={communicationPreferences?.phoneNumber}

                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Tone and Style *
                    </Form.Label>


                    <div className="SlectBox text-black">
                        <Select
                            options={[
                                { value: "Formal", label: "Formal" },
                                { value: "Informal", label: "Informal" },
                                { value: "Conversational", label: "Conversational" },
                                { value: "Persuasive", label: " Persuasive" },
                            ]}
                            placeholder="Select Tone and Style"
                            classNamePrefix="formselect"
                            onChange={(e) => dispatch(setToneAndStyle(e?.value as string))}
                            value={{ value: communicationPreferences?.toneAndStyle, label: communicationPreferences?.toneAndStyle }}

                        />
                    </div>
                </FormGroup>

                <FormGroup>
                    <Form.Label className="form-label">
                        Social Media Handles
                    </Form.Label>
                    {socialMediaHandle?.map((value: SocialMediaHandle) => (
                        <Card className="text-dark mg-b-20 border">
                            <Card.Header className="px-4 py-2">
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mb-0 mg-t-3">
                                            {value?.name}
                                        </h6>
                                    </div>
                                    <div className="ms-auto">
                                        <Dropdown className=" show main-contact-star" onSelect={(e) => handleSelect(e, value)}>
                                            <Dropdown.Toggle
                                                variant=""
                                                className="new option-dots2"
                                                data-bs-toggle="dropdown"

                                            >
                                                <i className="fe fe-more-vertical  tx-18"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu shadow">
                                                {" "}
                                                <Dropdown.Item
                                                    className="dropdown-item"
                                                    eventKey={'edit'}
                                                >
                                                    Edit
                                                </Dropdown.Item>{" "}
                                                <Dropdown.Item
                                                    className="dropdown-item"
                                                    eventKey={'remove'}
                                                >
                                                    Delete
                                                </Dropdown.Item>{" "}

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className='py-0'>
                                <p className="mg-t-0">
                                    {value?.handle}
                                </p>
                            </Card.Body>
                        </Card>
                    ))}
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">

                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        onChange={(e) => setName(e?.target?.value)}
                        value={name}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Handle"
                        onChange={(e) => setHandle(e?.target?.value)}
                        value={handle}
                    />
                </FormGroup>
                {!showSave ? <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleSocialMedia}
                >
                    Add
                </Button> :
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => saveEdit({ name, handle })}
                    >
                        Save
                    </Button>}

                <div className="py-3 mt-5">
                    {isBusy ?
                        <div className="text-center">
                            <div className="lds-dual-ring"></div>
                        </div> :
                        <button
                            className={"btn btn-primary"}
                            onClick={(e: any) => { onSubmit(); e?.preventDefault() }}
                        >
                            Save
                        </button>
                    }

                </div>
            </Form>
        </section>

    )
}

export default Communication