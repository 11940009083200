import React, { useEffect, useState } from "react";
import useListing from "../../../../hooks/useListing";
import { updateStep } from "../../../../redux/active-product/currentStepSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { WizardGrid } from "../../../../style/wizard-style";
import { RiMenuFoldLine, RiMenuUnfoldFill } from "react-icons/ri";

function Buttons({ visible, ...props }: any) {
    return (
        <button className={visible ? "btn btn-outline-primary " : "invisible"} {...props} />
    );
}

function getClsNavBtns(active: any) {
    return "btn horwizard" + (active ? " active" : "");
}

export default function Wizard({ step: currentIndex, ...props }: any) {
    const steps: any = React.Children.toArray(props?.children);
    const dispatch = useDispatch();
    const handleSubmit = ({ step, title }: { step: number, title: string }) => {
        dispatch(updateStep({ step, title }));
    }
    const stepList = [
        { title: 'Product Descriptions', number: 1 },
        { title: 'Features and benefits', number: 2 },
        { title: 'Pricing and packages', number: 3 },
        { title: 'Success stories', number: 4 },
        { title: 'Testimonials and social proof', number: 5 },
        { title: 'Images', number: 6 }
    ]
    const [collapse, setCollapse] = useState<boolean>(false);
    return (
        <WizardGrid>
            <div className={`${collapse ? 'collapsed-grid' : null} wizard-grid`}>
                <nav className="steps border  bg-white bs">
                    <div className="steps-cover">
                        <button
                            className='btn horwizard toggle-btn'
                            onClick={() => setCollapse(!collapse)}
                        >
                            <span className="number me-2 bg-white">
                                {collapse ? <RiMenuUnfoldFill size={22} className="text-muted" /> :
                                    <RiMenuFoldLine size={22} className="text-muted" />}
                            </span>
                        </button>
                        {stepList.map((step: any, index: any) => (
                            <a href={step?.number > 1 ? `#${step?.title}` : `#`} key={index} className="w-100">
                                <Buttons
                                    onClick={(e: any) => {
                                        handleSubmit({ step: step.number - 1, title: step.title });
                                        // e.preventDefault();
                                    }}
                                    className={getClsNavBtns(index === currentIndex)}
                                >
                                    <span className="number me-2">{step.number}</span>
                                    {!collapse ? <i>{step.title}</i> : null}
                                </Buttons>
                            </a>
                        ))}
                    </div>
                </nav>
                <div>
                    {steps?.map((value: any, index: number) => <div className={`border ${steps?.length - 1 == index ? '' : 'mb-5'} bg-white bs`}>
                        <div className="p-4 pb-0">
                            <p className="pb-0 fs-4 mb-0">{stepList[index]?.title}</p>
                        </div>
                        {value}
                    </div>)}
                </div>
                <div className="col-sm-3 bordera"></div>
            </div>
        </WizardGrid>
    );
};
