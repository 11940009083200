import styled from "styled-components";

export const WizardGrid = styled.div`
.wizard-grid{
display:grid;
// grid-template-columns:200px 1fr;
gap:15px;
.steps-cover{
    overflow:hidden;
    button{
       display:flex !important;
       align-items:center;
    }
}
}
.pb-0{
padding-bottom:0 !important;
}
.bs{
    box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1)
}
.toggle-btn{
    display:none !important;
    }
    
    
@media (min-width:920px){
.wizard-grid{
display:grid;
grid-template-columns:350px minmax(60%,1fr);
gap:15px;
.steps-cover{
    position:fixed;
}
}
.toggle-btn{
    display:block;
}
.wizard-grid.collapsed-grid{
    display:grid;
    grid-template-columns:60px minmax(60%,1fr);
    gap:15px;
    .steps-cover{
        position:fixed;
        width:60px;
        overflow:hidden;
        transform:translateX(-22px);
    }
.spin{
    animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }

}

}

`