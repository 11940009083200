import React, { useState } from 'react';
import { Button, Card, Dropdown, Form, FormGroup, Row } from "react-bootstrap";
import { HighlightedFeatures } from "../../../../redux/active-product/model";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../../redux/store/store';
import { updateStep } from '../../../../redux/active-product/currentStepSlice';
import { updateProductFeaturesAndBenefits } from '../../../../redux/active-item/active-itemSlice';


export default function FeaturesAndBenefits() {
    const dispatch = useDispatch();

    const featuresAndBenefits = useSelector((state: RootState) => state.activeItem.activeProduct.featuresAndBenefits);
    const activeProduct = useSelector((state: RootState) => state.activeItem.activeProduct);
    console.log({activeProduct});
    
    const features = featuresAndBenefits?.hightlightedFeatures;
    const benefits = featuresAndBenefits?.benefits;

    const [featureTitle, setFeatureTitle] = useState<string>('');
    const [featureDescription, setFeatureDescription] = useState<string>('');
    const [featureError, setFeatureError] = useState<HighlightedFeatures>({
        title: "",
        description: "",
    });

    const [benefitTitle, setBenefitTitle] = useState<string>('');
    const [benefitError, setBenefitError] = useState<string>("");

    const handleHightlightFeatures = async () => {
        if (featureTitle && featureDescription) {
            setFeatureError({ title: "", description: "" });
            let tempFeatures = {
                title: featureTitle,
                description: featureDescription,
            };
            dispatch(updateProductFeaturesAndBenefits(
                {hightlightedFeatures: 
                    [...features?.filter(i => i != tempFeatures), tempFeatures], 
                benefits}
            ));
            setFeatureTitle("");
            setFeatureDescription("");
        } else {
            if (!featureTitle && !featureDescription) {
                setFeatureError({
                    title: "Feature title is a required field",
                    description: "Feature description is a required field",
                });
            } else if (!featureTitle) {
                setFeatureError((pre) => ({
                    title: "Feature title is a required field",
                    description: "",
                }));
            } else if (!featureDescription) {
                setFeatureError((pre) => ({
                    title: "",
                    description: "Feature description is a required field",
                }));
            }
        }
    };

    const handleBenefits = async () => {
        if (benefitTitle) {
            setBenefitError("");
            dispatch(updateProductFeaturesAndBenefits(
                {hightlightedFeatures: [...features], 
                benefits: [...benefits?.filter(i => i != benefitTitle), benefitTitle]}
            ));
            setBenefitTitle("");
        } else {
            if (!benefitTitle) {
                setBenefitError("Benefit title is a required field");
            }
        }
    };

    const removeFeature = (feature: HighlightedFeatures) => {
        let filteredFeatures = features?.filter(
            (value) =>
                value?.title != feature?.title &&
                value?.description != feature?.description
        );
        if (filteredFeatures) {
            dispatch(updateProductFeaturesAndBenefits({benefits, hightlightedFeatures: [...filteredFeatures]}));
        }
    };

    const removeBenefit = (benefit: string) => {
        let filteredBenefits = benefits?.filter(
            (value) =>
            value != benefit
        );
        if (filteredBenefits) {
            dispatch(updateProductFeaturesAndBenefits({benefits: [...filteredBenefits], hightlightedFeatures: features}));
        }
    };

    const handleBasicInfo = () => {
        dispatch(updateStep({ step: 3, title: 'Pricing and packages' }));
    }

    return (
        <section className="card-body">
            <Form onSubmit={() => handleBasicInfo()} id="Features and benefits">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Highlight key features
                    </Form.Label>
                    <div className='mt-2'>
                        {features?.map((value, index) => {
                            return <Card className="mg-b-20 border" key={index}>
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                {value?.title}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            setFeatureTitle(value?.title);
                                                            setFeatureDescription(value?.description);
                                                            removeFeature(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removeFeature(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value?.description}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <Form.Control
                        type="text"
                        className="form-control mt-3"
                        // required
                        value={featureTitle}
                        placeholder="Feature Title"
                        onChange={(e) => {
                            setFeatureTitle(e?.target?.value);
                        }}
                    />
                    {featureError?.title && (
                        <p className={"text-error"}>
                            {featureError?.title}
                        </p>
                    )}
                    <div className="mt-3">
                        <textarea
                            className="form-control"
                            placeholder="Feature Description"
                            rows={5}
                            value={featureDescription}
                            onChange={(e) => {
                                setFeatureDescription(e?.target?.value);
                            }}
                        ></textarea>
                        {featureError?.description && (
                            <p className={"text-error"}>
                                {featureError?.description}
                            </p>
                        )}
                    </div>
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handleHightlightFeatures}
                    >
                        Add
                    </Button>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Benefits
                    </Form.Label>
                    <div className='mt-2'>
                        {benefits?.map((value, index) => {
                            return <Card className="mg-b-20 border" key={index}>
                                <Card.Header className="px-4 pb-0">
                                    <div className="media">
                                        <div className="media-body">
                                            <h6 className="mb-0">
                                                Benefit #{index+1}
                                            </h6>
                                        </div>
                                        <div className="ms-auto">
                                            <Dropdown className=" show main-contact-star">
                                                <Dropdown.Toggle
                                                    variant=""
                                                    className="new option-dots2"
                                                    data-bs-toggle="dropdown"

                                                >
                                                    <i className="fe fe-more-vertical  tx-18"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu shadow">
                                                    {" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            setBenefitTitle(value);
                                                            removeBenefit(value);
                                                        }}
                                                    >
                                                        Edit
                                                    </Dropdown.Item>{" "}
                                                    <Dropdown.Item
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={() => {
                                                            removeBenefit(value);
                                                        }}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>{" "}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-4 h-100">
                                    <p className="mg-t-0">
                                        {value}
                                    </p>
                                </Card.Body>
                            </Card>
                        })}
                    </div>
                    <textarea
                        className="form-control"
                        placeholder="Benefit"
                        rows={5}
                        value={benefitTitle}
                        onChange={(e) => {
                            setBenefitTitle(e?.target?.value);
                        }}
                    ></textarea>
                    {benefitError && (
                        <p className={"text-error"}>
                            {benefitError}
                        </p>
                    )}
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={handleBenefits}
                    >
                        Add
                    </Button>
                </FormGroup>
            </Form>

        </section>
    );
}
