import xss, {whiteList} from "xss";

export const MAX_TOKENS = 4097;
export const PROMPT_TYPES = {
  suggestMarketSegment: "suggestMarketSegment",
  generateMarketingCopy: "generateMarketingCopy"
}

export const tokens = (s: string) => {
  const blocks = tokenTree(s);
  const t: string[] = [];
  for(let b of blocks)
    t.push(...b.tokens)
  return [...new Set(t)];
}

interface Block {
  phrase: string;
  tokens: string[];
}

export const tokenTree = (s: string) => {
  const START = "{", END = "}";
  //Example
  // [
  //   { phrase: "", tokens: ["productName", "companyName", "productDescription"]},
  //   { phrase: `{to a lead named "{leadName}"}`, tokens: ["leadName"]},
  //   { phrase: `{from the organization "{leadCompany}" belonging to the market segment "{marketSegment}"}`, tokens: ["leadCompany", "marketSegment"]},
  //   { phrase: "{The intent of the pitch is to {Intent}}", tokens: ["Intent"] },
  //   { phrase: `{The description is intended for {audience} audience and should be written in a {tone} tone. }`, tokens: ["audience", "tone"]}
  // ];

  const t = s.split("{").map(p => p.split("}"));
  const rootBlock : Block = {phrase: "", tokens: []};

  let tokens: string[] = [];
  let phrase = "";
  let collecting = false;
  const blocks: Block[] = [];
for(let i=1;i<t.length;i++) {
    const parts = t[i];
    if(parts.length == 2) {
      //both { and } found, add to 
      tokens.push(parts[0]);
      if(collecting)
        phrase = `${phrase}{${parts[0]}}${parts[1]}`;
    } else if(parts.length == 1) {
      //{ found, but no } yet, start collecting
      phrase = "{" + parts[0]; 
      if(tokens.length > 0)
        rootBlock.tokens.push(...tokens);
      tokens = [];
      collecting = true;
    } else if(parts.length == 3) {
      //Extra } found, should be the closure of a prior {
      tokens.push(parts[0]);  
      phrase = `${phrase}{${parts[0]}}${parts[1]}}`;
      blocks.push({phrase, tokens});
      phrase = "";
      tokens = [];
      collecting = false;
    }
  }
  if(tokens.length > 0)
  rootBlock.tokens.push(...tokens);
  blocks.push(rootBlock);
  return blocks;
}

export const countOccurences = (string: string, word: string) => {
   return string.split(`{${word}}`).length - 1;
}

const buildPrefix = (prefix: string, current: string) => prefix == "" ? current : `${prefix}.${current}`;

const recursiveFields = (obj: Object, prefix: string) => {
  const fields: {value: string, label: string}[] = [];
  if(!!obj) {
    for(let key of Object.keys(obj)) {
      console.log("prefix", prefix, "key", key, "value", obj[key as keyof Object]);
      let child = obj[key as keyof Object];
      let isArray = false;
      if(child instanceof Array) {
        child = child?.[0] ?? "";
        isArray = true;
      }
      if(typeof child === 'object') {
        fields.push({value: buildPrefix(prefix, key), label: buildPrefix(prefix, key)});
        fields.push(...recursiveFields(child, buildPrefix(prefix, key))); 
        if(isArray) {
          fields.push(...recursiveFields(child, buildPrefix(prefix, `${key}.0`))); 
          fields.push(...recursiveFields(child, buildPrefix(prefix, `${key}.1`))); 
          fields.push(...recursiveFields(child, buildPrefix(prefix, `${key}.2`))); 
          fields.push(...recursiveFields(child, buildPrefix(prefix, `${key}.3`))); 
          fields.push(...recursiveFields(child, buildPrefix(prefix, `${key}.4`))); 
        }
      } else {
        fields.push({value: buildPrefix(prefix, key), label: buildPrefix(prefix, key)});
        if(isArray) {
          fields.push({value: buildPrefix(prefix, `${key}.0`), label: buildPrefix(prefix, `${key}.0`)});
          fields.push({value: buildPrefix(prefix, `${key}.1`), label: buildPrefix(prefix, `${key}.1`)});
          fields.push({value: buildPrefix(prefix, `${key}.2`), label: buildPrefix(prefix, `${key}.2`)});
          fields.push({value: buildPrefix(prefix, `${key}.3`), label: buildPrefix(prefix, `${key}.3`)});
          fields.push({value: buildPrefix(prefix, `${key}.4`), label: buildPrefix(prefix, `${key}.4`)});
        }
      }
    }
  }
  fields.sort((a,b) => a.value < b.value ? -1 : 1);
  return fields;
}

export const getFields = (dataset: {data: any}) => {
    const {data} = dataset;
    const fields = [{value:"", label: ""}, ...recursiveFields(data, "")];
    return fields;
}

export const SYS_FIELDS = {
  LIST_SEPARATOR: "---"
}
export const TEMPLATE_FIELDS = {
  PRIMARY_NAME: "John Smith"
};

export const serialize = (obj: any) => {
  let s = "";
  for(let key of Object.keys(obj)) {
    s += `${key}: ${obj[key]}\n`
  }
  s += `${SYS_FIELDS.LIST_SEPARATOR}\n`;
  return s;
}

const getFormattedValue = (v: any) => {
  if(v instanceof Array) {
    if(v.length > 0)
      v = v.map(vi => getFormattedValue(vi)).join("\n")
    else
      v = "";
  }
  if(typeof v == 'object') {
    v = serialize(v);
  } if(typeof v === undefined || v === null  || v == null || v == "") 
      v = "";
  return v;
}

export const substituteShallow = (template: string, data: Record<string, string>) => {
  let s = template;
  // console.log("template", template);
  const blocks = tokenTree(template);
  
  // console.log("blocks", blocks);
  for(let block of blocks) {
    if(!block.phrase) // skip processing root block 
      continue;
    //make sure value exists for all tokens, if not skip
    let r = block.phrase;
    for(let t of block.tokens) {
      let v = valueForKeyPath(data, t);
      // if(v instanceof Array)
      //   v = v?.[0];
      v = getFormattedValue(v);
      if(!v) {
        r = "";
      } else {
        r = r.replaceAll(`{${t}}`, v)
      }
    }
    r = r.replace("{", "").replace("}", "");
    console.log("block.phrase", block.phrase, "r", r);
    s = s.replaceAll(block.phrase, r);
  }
  const rootBlock = blocks.find(b => b.phrase == "");
  if(rootBlock) {
    for(let t of rootBlock.tokens) {
      let v = valueForKeyPath(data, t);
      if(typeof v === undefined || v === null || v == null) 
          v = "";
      if(v instanceof Array)
        v = getFormattedValue(v);
      if(typeof v == 'object') 
        v = getFormattedValue(v);
      v = xss(v, {whiteList});
      s = s.replaceAll(`{${t}}`, v)
    }
  }

  for(let field of Object.keys(SYS_FIELDS)) {
    s = s.replaceAll(`(${field})`, `"${SYS_FIELDS[field as keyof typeof SYS_FIELDS]}"`);
  }
  for(let field of Object.keys(TEMPLATE_FIELDS)) {
    s = s.replaceAll(`(${field})`, `"${TEMPLATE_FIELDS[field as keyof typeof TEMPLATE_FIELDS]}"`);
  }
  // console.log("substituted", s);
  return s;
}

export const substitute = (template: string, fields: Record<string, string>, data: {product: any, user: any, company: any}) => {
  let s = template;
  // console.log("template", template);
  const blocks = tokenTree(template);
  
  // console.log("blocks", blocks);
  for(let block of blocks) {
    if(!block.phrase) // skip processing root block 
      continue;
    //make sure value exists for all tokens, if not skip
    let r = block.phrase;
    for(let t of block.tokens) {
      let v = valueForKeyPath(data, fields[t]);
      // if(v instanceof Array)
      //   v = v?.[0];
      v = getFormattedValue(v);
      if(!v) {
        r = "";
      } else {
        r = r.replaceAll(`{${t}}`, v)
      }
    }
    r = r.replace("{", "").replace("}", "");
    console.log("block.phrase", block.phrase, "r", r);
    s = s.replaceAll(block.phrase, r);
  }
  const rootBlock = blocks.find(b => b.phrase == "");
  if(rootBlock) {
    for(let t of rootBlock.tokens) {
      let v = valueForKeyPath(data, fields[t]);
      if(typeof v === undefined || v === null || v == null) 
          v = "";
      if(v instanceof Array)
        v = getFormattedValue(v);
      if(typeof v == 'object') 
        v = getFormattedValue(v);
      v = xss(v, {whiteList});
      s = s.replaceAll(`{${t}}`, v)
    }
  }

  for(let field of Object.keys(SYS_FIELDS)) {
    s = s.replaceAll(`(${field})`, `"${SYS_FIELDS[field as keyof typeof SYS_FIELDS]}"`);
  }
  for(let field of Object.keys(TEMPLATE_FIELDS)) {
    s = s.replaceAll(`(${field})`, `"${TEMPLATE_FIELDS[field as keyof typeof TEMPLATE_FIELDS]}"`);
  }
  // console.log("substituted", s);
  return s;
}

export function valueForKeyPath(obj: any, path: string): any {
  if(!!path) {
    const keys = path.split('.');
    const key = keys[0];
    if(keys.length == 1)
      return !!obj ? obj[key] : "";
    else {
      const v = !!obj ? isNaN(key as any) ? obj[key] : obj[parseInt(key)] : "";
      if(v instanceof Array)
        if(keys.length > 1 && !isNaN(keys[1] as any)) //if accessing a specific array index, proceed
          return valueForKeyPath(v, keys.slice(1).join("."));
        else
          return v.map(vi => valueForKeyPath(vi, keys.slice(1).join(".")));
      else if(!v)
        return "";
      else
        return valueForKeyPath(v, keys.slice(1).join("."));
    }
  } else 
    return "";
};

export function asString(obj: any) {
  let value = obj;
  if(!!value) {
    if(value instanceof Array) {
      if(value.length > 0 && typeof value[0] == 'object')
        value = value.map(v => serialize(v)).join(", ");
      else
        value = value?.join(", ");
    } if(typeof value == 'object') {
      value = serialize(value);
    }
    return value.toString() ?? "";
  } else {
    return "";
  }
}

export function isHTML(str: string) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}


let sampleText = `Your task is to help a marketing team write an email {to a lead named "{leadName}"} {from the organization "{leadCompany}"} {belonging to the market segment "{marketSegment}"} about the benefits of "{productName}" from "{companyName}" based on the information provided in the specifications delimited by triple backticks.

{The intent of the pitch is to {Intent}}

The email should be no longer than 5 sentences.

{The description is intended for {audience} audience and should be written in a {tone} tone. }

specifications:{productDescription}`;

let sampleOutput: Block[] = [
  { phrase: '{to a lead named "{leadName}"}', tokens: [ 'leadName' ] },
  {
    phrase: '{from the organization "{leadCompany}"}',
    tokens: [ 'leadCompany' ]
  },
  {
    phrase: '{belonging to the market segment "{marketSegment}"}',
    tokens: [ 'marketSegment' ]
  },
  {
    phrase: '{The intent of the pitch is to {Intent}}',
    tokens: [ 'Intent' ]
  },
  {
    phrase: '{The description is intended for {audience} audience and should be written in a {tone} tone. }',
    tokens: [ 'audience', 'tone' ]
  },
  {
    phrase: '',
    tokens: [ 'productName', 'companyName', 'productDescription' ]
  }
]