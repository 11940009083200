import {useState} from "react";
import { httpsCallable } from "firebase/functions";
import { collection, query, where, Query, CollectionReference } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useFunctions } from "reactfire";
import { ChatCompletionResponse } from "../../models/chat";
import useProducts from "../useProducts";
import { FIRESTORE } from "../../Firebase/firestore";
import { PromptData } from "../../components/Pages/Prompt/Manage/models";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { CampaignTemplate } from "../../models/campaign";
import { Prompt } from "../../Firebase/models";
import { substitute } from "../../util/promptutil";
import { LeadSourceAndContact } from "../../models/product";

export default function useMarketingCampaign() {
  const functions = useFunctions();
  const [loading, setLoading] = useState(false);
  const {products} = useProducts();
  const firestore = useFirestore();

  const q = query(collection(firestore, FIRESTORE.PROMPT_TEST_DATA), where("type", "==", "marketing")) as Query<PromptData>;
  const { data: datasets, status: datasetLoadStatus } = useFirestoreCollectionData(q, { idField: "id" });

  const promptCollection = collection(firestore, FIRESTORE.PROMPT) as CollectionReference<Prompt>;
  const { data: prompts, status: promptsLoadStatus } = useFirestoreCollectionData(promptCollection, { idField: "id" });

  const campaignTemplateCollection = collection(firestore, FIRESTORE.CAMPAIGN_TEMPLATE) as CollectionReference<CampaignTemplate>;
  const { data: campaignTemplates, status: campaignTemplateLoadStatus } = useFirestoreCollectionData(campaignTemplateCollection, { idField: "id" });

  const activeCampaign = useSelector((state: RootState) => state.activeItem.activeCampaign);
  const templateId = activeCampaign.templateId ?? "Email Marketing Campaign";
  const promptId = campaignTemplates?.find(t => t.id == templateId)?.promptId;
  const prompt = prompts?.find(p => p.id == promptId);

  const liveData = useSelector((state: RootState) => ({
    id: state.activeItem.activeCampaign?.name ?? "",
    name: state.activeItem.activeCampaign?.name ?? "",
    data: {
    user: state.activeItem.activeUser as unknown as  Record<string,string>, 
    company: {...state.activeItem.activeCompany.basicCompanyInfo, competitors: state.activeItem.activeCompany.competitorsInfo} as unknown as Record<string,string>, 
    campaign: {...state.activeItem.activeCampaign, ...state.activeItem.activeCampaign.goal, ...state.activeItem.activeCampaign.tone} as unknown as Record<string,string>, 
    product: products?.find(p => p.id == state.activeItem.activeCampaign.productId) as unknown as Record<string,string>}}));

  const testDatasets = datasets ? !!liveData.id ? [{value: liveData, label: liveData.name}, ...datasets?.map(p => ({ value: p, label: p.name }))] :
    datasets?.map(p => ({ value: p, label: p.name })) : [];

  const promptText = prompt?.prompt ?? "";
  const promptTextWithSubstitution = substitute(prompt?.prompt ?? "", prompt?.substitutions ?? {}, liveData?.data);
  const finalPromptText = `${promptTextWithSubstitution} \n\n ${prompt?.outputFormat ? ", " + prompt?.outputFormat : ""}`;
    
  const generate = async (lead?: LeadSourceAndContact): Promise<string> => {
    setLoading(true);
    const data = !!lead ? {...liveData, data: {...liveData.data, user: lead  as unknown as  Record<string,string>}} : liveData;
    const chatGPT = httpsCallable(functions, "chatGPT-chatGPTCall");
    const substituted = substitute(prompt?.prompt ?? "", prompt?.substitutions ?? {}, data.data);
    const promptContent = `${substituted} ${prompt?.outputFormat ? ", " + prompt?.outputFormat : ""}`;
  
    try {
    const response = await chatGPT({ requestType: "completion", message: {role: "user", content: promptContent}});
    const result = (response.data as ChatCompletionResponse);
    setLoading(false);
    return result.content as string;
    } catch(err) {
      console.log(err);
      setLoading(false);
      return "Error while fetching prompt execution result, please try again later"
    }
  }

  return { testDatasets, generate, loading, promptText, finalPromptText };
}