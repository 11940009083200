import {useState} from "react";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "reactfire";
import { ChatCompletionResponse, ChatGPTFunctionCall } from "../models/chat";

enum RequestType {
  completion = "completion",
  imageGeneration = "imageGeneration",
  midjourneyImageGeneration = "midjourneyImageGeneration"
}

const CHAT_GPT_CALL = "chatGPT-chatGPTCall";

export default function useChatGpt() {
  const firebaseFunctions = useFunctions();
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  
  const findMarketFit = async (promptText: string, outputFormat?: string): Promise<string[]> => {
    setLoading(true);
    const chatGPT = httpsCallable(firebaseFunctions, CHAT_GPT_CALL);
    setPrompt(promptText);
    const content = `${promptText}, ${outputFormat ?? "return as a string array"}`;
    try {
    const response = await chatGPT({ requestType: RequestType.completion, message: {role: "user", content}});
    const result = (response.data as ChatCompletionResponse);
    setLoading(false);
    return JSON.parse(result.content!) as string[];
    } catch(err) {
      console.log(err);
      setLoading(false);
      return ["Error while fetching market fit suggestions, please try again later"]
    }
  }

  const runPrompt = async (promptText: string, functions: string | undefined, outputType: "array" | "function" | "html" | "json" | "text", outputFormat: string): Promise<ChatCompletionResponse> => {
    setLoading(true);
    const chatGPT = httpsCallable(firebaseFunctions, CHAT_GPT_CALL);
    setPrompt(promptText);
    const fnJson = (functions?.length ?? 0) > 0 ? JSON.parse(functions!) : undefined;
    const content = outputType == "function" ? promptText : `${promptText}, ${outputFormat ?? "return as a string array"}`;
    try {
    const response = await chatGPT({ requestType: RequestType.completion, message: {role: "user", content}, functions: fnJson});
    const result = (response.data as ChatCompletionResponse);
    setLoading(false);
    return result;
    } catch(err) {
      console.log(err);
      setLoading(false);
      throw new Error("Error while running prompt, please try again later");
    }
  }

  const runFunctionPrompt = async (promptText: string, functions: string | undefined, outputFormat: string): Promise<ChatGPTFunctionCall> => {
    const response = await runPrompt(promptText, functions, "function", outputFormat);
    if(!response.function_call)
      throw new Error('Error while running function prompt. No function returned. Please try again later');
    const fn = response.function_call;
    if(typeof fn.arguments === "string")
      fn.arguments = JSON.parse(fn.arguments);
    return fn;
  }

  const runStringPrompt = async (promptText: string, functions: string | undefined, outputFormat: string): Promise<string> => {
    const response = await runPrompt(promptText, functions, "text", outputFormat);
    if(!response.content)
      throw new Error('Error while running prompt. No content returned. Please try again later');
    return response.content!;
  }

  const runStringArrayPrompt = async (promptText: string, functions: string | undefined, outputFormat: string): Promise<string[]> => {
    const response = await runPrompt(promptText, functions, "array", outputFormat);
    if(!response.content)
      throw new Error('Error while running prompt. No content returned. Please try again later');
    return JSON.parse(response.content) as string[];
  }

  async function runJsonPrompt<T>(promptText: string, functions: string | undefined, outputFormat: string): Promise<T> {
    const response = await runPrompt(promptText, functions, "json", outputFormat);
    if(!response.content)
      throw new Error('Error while running prompt. No content returned. Please try again later');
    return JSON.parse(response.content) as T;
  }

  return { findMarketFit, runStringPrompt, runStringArrayPrompt, runJsonPrompt, runFunctionPrompt, loading, prompt };
}