import React, { useEffect, useState } from "react";

function Buttons({ visible, ...props }: any) {
  return (
      <button className={visible ? "btn btn-outline-primary " : "invisible"} {...props} />
  );
}

function getClsNavBtns(active: any) {
  return "btn horwizard" + (active ? " active" : "");
}

export default function Wizard({ step: currentIndex, ...props }: any) {
  const steps: any = React.Children.toArray(props.children);
  const prevStep: any = currentIndex !== 0 && steps[currentIndex - 1].props;
  const nextStep: any =
      currentIndex !== steps.length - 1 && steps[currentIndex + 1].props;
  return (
      <div className="row border">
          <nav className=" steps col-sm-3 bordera">
              {steps.map((step: any, index: any) => (
                  <Buttons
                      key={index}
                      onClick={(e: any) => {props.onChange(index); e.preventDefault();}}
                      className={getClsNavBtns(index === currentIndex)}
                      disabled={step.props.disabled}
                  >
                      <span className="number me-2">{step.props.number}</span>
                      <i>{step.props.title}</i>
                  </Buttons>
              ))}
          </nav>

          {steps[currentIndex]}
          <div className="col-sm-3 bordera"></div>
          <div className=" p-3 d-flex justify-content-between col-sm-9">
              <Buttons
                  visible={prevStep}
                  onClick={(e: any) => {props.onChange(currentIndex - 1);e.preventDefault();}}
                  title={prevStep.description}
              >
                  Back
              </Buttons>
              <Buttons
                  visible={nextStep}
                  onClick={(e: any) => {props.onChange(currentIndex + 1);e.preventDefault();}}
                  title={nextStep.description}
                  type={"submit"}
                  form={"prompt-form"}
              >
                  Next
              </Buttons>
          </div>
      </div>
  );
};
