import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../../redux/store/store';
import { blankProduct, Prompt } from '../../../../Firebase/models';
import useFolders from '../../../../hooks/useFolders';
import { FIRESTORE } from '../../../../Firebase/firestore';
import { selectPrompt } from '../../../../redux/active-prompt/activePromptSlice';
import Select from "react-select";
import { asString, countOccurences, getFields, isHTML, tokens, valueForKeyPath } from '../../../../util/promptutil';
import { Prev } from 'react-bootstrap/esm/PageItem';
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { doc, setDoc, collection, CollectionReference } from "firebase/firestore";
import { PromptData } from './models';
import useProducts from '../../../../hooks/useProducts';
import useMarketingCampaign from '../../../../hooks/prompts/useMarketingCampaign';


export default function PromptMapping() {
  const { saveFile: savePrompt, loading: saving } = useFolders<Prompt>(FIRESTORE.PROMPT);
  const dispatch = useDispatch();
  const datasetCollection = collection(useFirestore(), FIRESTORE.PROMPT_TEST_DATA) as CollectionReference<PromptData>;
  const { data: datasets, status } = useFirestoreCollectionData(datasetCollection, { idField: "id" });

  const {testDatasets: datasetNamesList} = useMarketingCampaign();
  const {products} = useProducts();

  const prompt = useSelector((state: RootState) => state.activePrompt.prompt);
  const [fields, setFields] = useState<{ value: string, label: string }[]>(Object.keys(prompt.substitutions ?? {}).map(f => ({ label: prompt.substitutions[f], value: prompt.substitutions[f] })))
  const [selectedDataset, setSelectedDataset] = useState<PromptData | undefined>();

  useEffect(() => {
    if (!selectedDataset)
      return;
    setFields(getFields(selectedDataset));
  }, [selectedDataset])
  const schema = yup
    .object({
      name: yup.string().required("Prompt name is a required field"),
      prompt: yup.string().required("Prompt text is a required field"),
      outputFormat: yup.string().required("Output Format is a required field"),
    })
    .required();
  const substitutionTokens = tokens(prompt.prompt);
  const { register, control, reset, handleSubmit, formState: { isDirty, errors, isValid } } =
    useForm<Prompt>({ mode: "all", resolver: yupResolver(schema), defaultValues: prompt }); //, defaultValues: basicInfo
  const [substitutions, setSubstitutions] = useState<Record<string, string>>(prompt?.substitutions);

  useEffect(() => {
    if (prompt) {
      // Reset the values in the form
      reset(prompt);
      setSubstitutions(prompt.substitutions);
    }
  }, [prompt, reset]);

  const onSubmit = (data: Prompt, e: any) => {
    savePrompt({ ...data, substitutions });
    dispatch(selectPrompt(data));
  }
  // console.log({ substitutionTokens, substitutions, fields });
  return (
    <section className="card-body p-4" id="Mapping">

      <Form onSubmit={handleSubmit(onSubmit)} id="prompt-form">
        <Row className="row-sm">
          <Col md={12} xl={12}>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <h4 className="card-title text-black-50">Substitutions</h4>
                </div>
                <FormGroup className="control-group form-group text-black">
                  <Form.Label className="form-label">
                    Dataset
                  </Form.Label>
                  <Select
                    onChange={(sel) =>
                      setSelectedDataset(sel?.value)
                    }
                    options={datasetNamesList}

                    value={datasetNamesList?.find(p => p.value.id == selectedDataset?.id)}
                    classNamePrefix="formselect"
                    isSearchable
                    placeholder="Select a dataset"
                  />
                </FormGroup>
                <Form.Label className="form-label">
                  Field substitutions
                </Form.Label>

                <div className="pd-30 pd-sm-20">
                {substitutionTokens?.map(t => {
                  const content = asString(valueForKeyPath(selectedDataset?.data, substitutions?.[t]));

                  return (
                  <Row className="row-xs align-items-center mg-b-20">
                    <Col md={3}>
                      <Form.Label className="form-label mg-b-0">
                        <b>{t}{" "}</b>
                      </Form.Label>
                    </Col>
                    <Col md={5} className=" mg-t-5 mg-md-t-0">
                    <Select
                              onChange={(sel) => setSubstitutions(prev => ({ ...prev, [t]: sel?.value ?? "" }))}
                              options={fields}
                              classNamePrefix="formselect"
                              isSearchable
                              placeholder="Select a field"
                              value={fields.find(f => f.value == substitutions?.[t])}
                            />
                    </Col>
                    <Col md={4} className=" mg-t-5 mg-md-t-0">

                            {content.startsWith("https://") ? 
                                <img width="80" src={asString(valueForKeyPath(selectedDataset?.data, substitutions?.[t]))} alt={content}></img>  :
                                isHTML(content) ? 
                                <div className='text-muted' style={{height: 80, overflow: 'clip'}} dangerouslySetInnerHTML={{__html: content}} /> :
                                <small className="text-muted ms-auto">
                                {content?.slice(0, 80)}</small>
                            }
                    </Col>
                  </Row>)
                })}
              </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <FormGroup className="control-group form-group">
          {saving ?
            <div className="text-center">
              <div className="lds-dual-ring"></div>
            </div>
            :
            <button className="btn btn-primary " type={"submit"}>
              Save
            </button>
          }
        </FormGroup>
      </Form>
    </section>
  );
}
