import React from 'react'
import { Gallery, ListingRequest, PRODUCT_STATUS, Product, ProductViewModel } from '../models/product';
import { useEffect, useState, useContext } from 'react';
import "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";
import { db, functions } from '../firebaseutil';
import slug from 'slug';

const PRODUCT = "product";
export enum RequestType {
    deleteProduct = "deleteProduct",
    listProduct = "listProduct",
    getGooglePlacesByQuery = "getGooglePlacesByQuery",
}
function useListing(productId: string) {

    const blankProduct: ProductViewModel = {
        id: "",
        businessName: "",
        sku: "",
        productName: "",
        description: "",
        hightlightedFeatures: [],
        status: PRODUCT_STATUS.ACTIVE,
        image: "",
        gallery: [],
        benefits: [],
        clientId: "",
    };
    const [productVM, setProductVM] = useState<ProductViewModel>(blankProduct);
    const [images, setImages] = useState<string[]>([]);
    const [isBusy, setIsBusy] = useState(false);
    const [updatingSeller, setUpdatingSeller] = useState(false);
    const [listingSuccessful, setListingSuccessful] = useState(false);

    const reconstruct = (vm: ProductViewModel, dbProduct: Product | undefined, images: Gallery[]): ListingRequest => {
        const { businessName, productName, description, 
            hightlightedFeatures, sku, benefits, complianceAndcertifications, 
            delivery, eventsOrActivities, gallery, id, image, newsOrExogenous, 
            pricing, status, successStories ,supportAndMaintenance, testimonials, clientId,
        } = vm;
        const idSlug = slug(`${productName}-${new Date().getTime()}`);

        const product: Product = {
            businessName, productName, description, hightlightedFeatures, sku,
            image: images?.[0]?.url,
            gallery: images,
            id: dbProduct?.id ?? idSlug,
            createdAt: dbProduct?.createdAt ?? new Date().toISOString(),
            lastUpdated: new Date().toISOString(),
            status: vm?.status ?? dbProduct?.status ?? PRODUCT_STATUS.ACTIVE,
            benefits: benefits ?? [],
            complianceAndcertifications, 
            delivery, eventsOrActivities,
            newsOrExogenous, 
            pricing, 
            successStories, 
            supportAndMaintenance, 
            testimonials,
            clientId,
        }
        return { product };
    };
    const fetchProduct = async (productId: string): Promise<Product> => {
        setIsBusy(true);
        const ref = doc(db, `${PRODUCT}/${productId}`);
        const product = (await getDoc(ref)).data() as Product;

        let vm;
        if (product) {
            vm = product;
            setProductVM(vm);
            setImages(product.gallery.map((g) => g.url));
        }
        setIsBusy(false);
        return product;
    };
    const save = async (productViewModel: ProductViewModel, images: Gallery[]): Promise<Product | string> => {
        setIsBusy(true);
        setUpdatingSeller(true);
        try {
            let dbProduct: ProductViewModel | undefined;
            if (!!productId && productId !== "new") {
                const ref = doc(db, `${PRODUCT}/${productId}`);
                dbProduct = (await getDoc(ref)).data() as ProductViewModel;
            }
            const listingRequest = reconstruct(productViewModel, dbProduct, images);
            console.log(listingRequest);

            const listProduct = httpsCallable(functions, "product-productCall");
            // console.log("Listing product", product);
            (await listProduct({ requestType: RequestType.listProduct, input: listingRequest })) as unknown as ProductViewModel;
            const savedProduct = await fetchProduct(listingRequest.product.id);
            // console.log("saved product", savedProduct);
            setProductVM(productViewModel);
            setImages(savedProduct.gallery.map((g) => g.url));
            setListingSuccessful(true);
            setIsBusy(false);
            return listingRequest?.product;
        } catch (err: any) {
            console.error("Error adding/updating product", err);
            setIsBusy(false);
            return err.toString();
        }
    };

    return { fetchProduct, save, isBusy }
}

export default useListing