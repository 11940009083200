import {useEffect, useState} from "react";
import { collection, deleteDoc, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { auth, db } from "../firebaseutil";
import { DbUser } from "../Firebase/models";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { Product } from "../models/product";
import { cloneName, cloneNumberStampedId } from "../util/util";

const rootCollection = FIRESTORE.PRODUCT;

export default function useProducts() {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const activeUser = useSelector((state: RootState) => state.activeItem.activeUser);  

  const appendproduct = (product: Product) => {
    setProducts(prev => [...prev.filter(pk => pk.id !== product.id), product])
  }

  useEffect(() => {
    if (!activeUser?.id){
     return;   
    }else{
        setLoading(true);
        const q = query(collection(db, `${rootCollection}`), where("clientId", "==", (activeUser?.clientIds?.[0] ?? "")));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type == "added") {
              appendproduct({...change.doc.data(), id: change.doc.id} as Product);
            } else if (change.type == "modified") {
              appendproduct({...change.doc.data(), id: change.doc.id} as Product);
            } else if (change.type == "removed") {
              setProducts(prev => prev.filter(p => p.id !== change.doc.data().id))
            }
          })
          setLoading(false);
        },(err)=>{console.log(err)});
        return unsubscribe;    
    }
  }, [activeUser?.id])
  
  const cloneProduct = async (product: Product) => {
    await setLoading(true);
    const newId = cloneNumberStampedId(product.id);
    const newProduct: Product = {...product, 
      id: newId,
      clientId:activeUser?.clientIds?.[0],
      productName:cloneName(product.productName),
      createdAt: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
    };
    
    const productRef = await doc(firestore, rootCollection, newId);
    await setDoc(productRef, newProduct, { merge: true });
    await setLoading(false);
  }
  return {loading, products,cloneProduct };
}