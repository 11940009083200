import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    FormGroup,
    Modal,
    Pagination,
    Row
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link, Router, useLocation, useNavigate, useParams } from "react-router-dom";
import { MdClose, MdOutlineClose, MdOutlineInfo } from "react-icons/md";
import useListing from "../../../../hooks/useListing";
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table";
import { COLUMNS, DATATABLE, GlobalFilter } from "../../../Dashboard/Dashboard-3/data";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../../redux/store/store";
import BasicInfo from "./BasicInfo";
import Wizard from "./Wizard";
import { removeRecentProduct, selectRecentProduct } from "../../../../redux/recent-activity/recentActivitySlice";
import { selectProduct, updateProductImages } from "../../../../redux/active-item/active-itemSlice";
import FeaturesAndBenefits from "./FeaturesAndBenefits";
import PricingAndPackages from "./PricingAndPackages";
import SucessStories from "./SucessStories";
import TestimonialsAndSocialProof from "./TestimonialsAndSocialProof";
import useImageUpload from "../../../../helper/useImageUpload";
import { Gallery, ProductViewModel } from "../../../../models/product";
import Swal from "sweetalert2";
import { updateStep } from "../../../../redux/active-product/currentStepSlice";
import { getNameFromImageUrl } from "../../../../util/util";



const Steps = ({ children }: any) => children;

function ManageProduct() {
    const { id, tab, } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let location = useLocation();
    const { uploadVariationImages } = useImageUpload();
    const [viewInstruction, setViewInstruction] = useState<boolean>(false);
    const { fetchProduct, save, isBusy } = useListing(id as string);
    const activeProduct = useSelector((state: RootState) => state.activeItem.activeProduct);
    const activeUser = useSelector((state: RootState) => state.activeItem.activeUser);
    const currentStep = useSelector((state: RootState) => state.step);
    const { gallery } = activeProduct;

    const getProduct = async () => {
        const pdt = await fetchProduct(id as string);
        if (!!pdt) {
            dispatch(selectProduct(pdt));
            dispatch(selectRecentProduct(pdt));
        }
    };

    useEffect(() => {
        if (id != 'new') {
            getProduct();
        } else {
            dispatch(selectProduct({
                id: "",
                businessName: "",
                sku: "",
                productName: "",
                description: "",
                hightlightedFeatures: [],
                status: "active",
                image: "",
                gallery: [],
                benefits: [],
                clientId: "",
            }));
        }
    }, [id]);

    const updateProduct = async () => {
        const productViewModal: ProductViewModel = {
            ...activeProduct?.basicInfo,
            ...activeProduct?.featuresAndBenefits,
            gallery: activeProduct?.gallery,
            id: (id != "new" ? id : "") ?? "",
            status: "active",
            clientId: activeUser?.clientIds?.[0] ?? "",
            image: activeProduct?.gallery?.[0]?.url,
            ...activeProduct?.pricingAndPackages,
            successStories: activeProduct?.successStories,
            ...activeProduct?.testimonialAndSocialProof
        }
        const result = await save(productViewModal, activeProduct?.gallery);
        if (typeof result === "string") {
            await Swal.fire({
                title: "Error !",
                text: result,
                allowOutsideClick: true,
                confirmButtonText: "Okay",
                cancelButtonColor: "#38cab3",
            });
        } else {
            const r = await Swal.fire({
                title: "Confirmation",
                text: `Product ${id === "new" ? "Created" : "Updated"} Successfully.`,
                allowOutsideClick: true,
                confirmButtonText: "Okay",
                cancelButtonColor: "#38cab3",
            });
            dispatch(updateStep({ title: 'Product Descriptions', step: 1 }));
            if (r?.isConfirmed && id === "new") {
                navigate(`/pages/product/${result?.id}`);
            }
        }
    }



    const close = async () => {
        dispatch(selectProduct({
            id: "",
            businessName: "",
            sku: "",
            productName: "",
            description: "",
            hightlightedFeatures: [],
            status: "active",
            image: "",
            gallery: [],
            benefits: [],
            clientId: "",
        }));
        dispatch(removeRecentProduct(activeProduct?.basicInfo?.sku));
    }
    const tableInstance = useTable(
        {
            columns: COLUMNS,
            data: DATATABLE,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps, // table props from react-table
        headerGroups, // headerGroups, if your table has groupings
        getTableBodyProps, // table body props from react-table
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        state,
        setGlobalFilter,
        page, // use, page or rows
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
    }: any = tableInstance;

    const { globalFilter, pageIndex, pageSize }: any = state;

    const Drop = () => {
        function formatBytes(bytes: any, decimals = 2) {
            if (bytes === 0) return "0 Bytes";
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        }
        async function handleAcceptedFiles(files: any) {
            await files.map(async (file: any) => {
                const result = await uploadVariationImages(file);
                dispatch(updateProductImages([...gallery?.map(i => i?.url), result]))
            });
        }
        const handleRemoveProductImage = (img: Gallery) => {
            const filteredImages = gallery?.filter((value) => img != value);
            dispatch(updateProductImages([...filteredImages?.map(i => i?.url)]));
        }

        return (
            <React.Fragment>
                <Dropzone
                    onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable">
                            <div className="dz-message needsclick" {...getRootProps()}>
                                <div className="mb-2 mt-5 dropzoneicon ">
                                    <i className="mdi mdi-apple-mobileme"></i>
                                </div>
                                <p style={{ color: "#9393b5" }}>
                                    Drop files here or click to upload.
                                </p>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className="list-unstyled mb-0" id="file-previews">
                    {gallery?.map((f: any, i: any) => {
                        return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2 d-flex align-items-center justify-content-between">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={getNameFromImageUrl(f?.url)}
                                                src={f.thumbnail}
                                            />
                                        </Col>
                                        <Col>
                                            <Link to="#" className="text-muted font-weight-bold">
                                                {getNameFromImageUrl(f?.url)}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                    </Row>
                                    <div className="cursor-pointer" onClick={() =>
                                        handleRemoveProductImage(f)
                                    }>
                                        <MdClose size={20} color={'#000'} />
                                    </div>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    };
    return (
        <div>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                        Product Information
                    </span>
                </div>
                <div>
                    <Link to={"/pages/products"}>
                        <button className="btn btn-primary" onClick={() => close()}>Close</button>
                    </Link>
                </div>
            </div>
            <Row>
                <Col lg={12} md={12}>
                    <div id="wizard3">
                        <Wizard step={currentStep?.step}>
                            <BasicInfo />
                            <FeaturesAndBenefits />
                            <PricingAndPackages />
                            <SucessStories />
                            <TestimonialsAndSocialProof />
                            {/* Images Section */}
                            <section className="card-body d-flex flex-column justify-content-between" id='Images'>
                                <FormGroup className="control-group form-group">
                                    <Form.Label className="form-label">
                                        Upload images of your product{" "}
                                        <MdOutlineInfo
                                            size={20}
                                            className="cursor-pointer"
                                            onClick={() => setViewInstruction(true)}
                                        />
                                    </Form.Label>
                                    <Modal show={viewInstruction}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                To ensure that your image meets the
                                                requirements, please follow the specifications
                                                below.
                                            </Modal.Title>
                                            <Button
                                                variant=""
                                                className="btn btn-close"
                                                onClick={() => {
                                                    setViewInstruction(false);
                                                }}
                                            >
                                                <MdOutlineClose size={25} />
                                            </Button>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <ul>
                                                <li>
                                                    Image size: Images should be at least 1000
                                                    pixels in height or width. Amazon recommends
                                                    2560 pixels on the longest side for optimal
                                                    resolution.
                                                </li>
                                                <li>
                                                    Image format: JPEG or TIFF format is
                                                    preferred. PNG is also accepted but not
                                                    recommended for high-quality images.
                                                </li>
                                                <li>
                                                    Background color: The product should be the
                                                    only thing in the image, and it should be on a
                                                    white background.
                                                </li>
                                                <li>
                                                    Image quality: Images should be high quality
                                                    and in focus, with no visible pixelation or
                                                    blurriness.
                                                </li>
                                                <li>
                                                    Image naming: Use descriptive names for your
                                                    images, including relevant keywords that can
                                                    help with SEO.
                                                </li>
                                                <li>
                                                    Image copyright: Make sure you have the legal
                                                    right to use any images you upload to your
                                                    website. Avoid using copyrighted images or
                                                    images that you do not have permission to use.
                                                </li>
                                            </ul>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    setViewInstruction(false);
                                                }}
                                            >
                                                Ok
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div className="mb-2 drop">
                                        <Drop />
                                    </div>
                                </FormGroup>
                                {isBusy ?
                                    <div className="text-center">
                                        <div className="lds-dual-ring"></div>
                                    </div> :
                                    <div className="py-3 d-flex justify-content-end">
                                        <button
                                            className={"btn btn-primary"}
                                            onClick={(e: any) => {
                                                updateProduct();
                                            }}
                                            type="submit"
                                            form="Basic Info"
                                        >
                                            {id === "new" ? "Create" : "Update"} Product
                                        </button>
                                    </div>}
                            </section>
                        </Wizard>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ManageProduct;
