import {useEffect, useState} from "react";
import { collection, deleteDoc, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { auth, db } from "../firebaseutil";
import { Company } from "../models/company";
import { DbUser } from "../Firebase/models";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";

const rootCollection = FIRESTORE.COMPANY;

export default function useCompanies(clientId?: string) {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const activeUser = useSelector((state: RootState) => state.activeItem.activeUser);

  const appendCompany = (company: Company) => {
    setCompanies(prev => [...prev.filter(pk => pk.id !== company.id), company])
  }

  useEffect(() => {
    if (!activeUser?.id){
     return;   
    }else{
        setLoading(true);
        const q = query(collection(db, `${rootCollection}`), where("clientId", "==", (activeUser?.clientIds?.[0] ?? "")));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type == "added") {
              appendCompany({...change.doc.data(), id: change.doc.id} as Company);
            } else if (change.type == "modified") {
              appendCompany({...change.doc.data(), id: change.doc.id} as Company);
            } else if (change.type == "removed") {
              setCompanies(prev => prev.filter(p => p.id !== change.doc.data().id))
            }
          })
          setLoading(false);
        },(err)=>{console.log(err)});
        return unsubscribe;    
    }
  }, [activeUser?.id])

  const saveCompany = async (file: Company) => {  
    let clientIds = activeUser?.clientIds ?? []; 
    let updateedUser: DbUser = {
      ...activeUser,
      ...activeUser.salesProfessionalInfo,
      ...activeUser.communicationPreferences, 
      ...activeUser.goalsAndObjectives,
      clientIds: [...clientIds?.filter(i => i != file?.clientId), file?.clientId]
    }
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await setDoc(fileRef, file, { merge: true });
    const fileRef1 = await doc(firestore, FIRESTORE.USER, file.userId);
    await setDoc(fileRef1, updateedUser, { merge: true });

    await setLoading(false);
  }

  const deleteCompany = async (id: string) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, id);
    await deleteDoc(fileRef);
    await setLoading(false);
  }

  return {loading, saveCompany, deleteCompany, companies };
}