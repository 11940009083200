import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    Card,
    Col,
    Row
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link, Router, useParams } from "react-router-dom";
import {
    useTable,
    useSortBy,
    useGlobalFilter,
    usePagination,
} from "react-table";
import { COLUMNS, DATATABLE, GlobalFilter } from "../../../Dashboard/Dashboard-3/data";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../../../redux/store/store";
import BasicInfo from "./BasicInfo";
import Wizard from "./Wizard";
import { collection, CollectionReference, doc, DocumentReference } from "firebase/firestore";
import { FIRESTORE } from "../../../../Firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from "reactfire";
import { Prompt } from "../../../../Firebase/models";
import { selectPrompt } from "../../../../redux/active-prompt/activePromptSlice";
import RunPrompt from "./RunPrompt";
import PromptMapping from "./PromptMapping";


const Steps = ({ children }: any) => children;

function ManagePrompt() {
    const { id, tab } = useParams();
    const dispatch = useDispatch();
    const firestore = useFirestore();
    const promptDocRef = doc(firestore, `${FIRESTORE.PROMPT}/${id}`) as DocumentReference<Prompt>;
    const { data: prompt, status } = useFirestoreDocData(promptDocRef, { idField: "id" });
    const activeFolder = useSelector((state: RootState) => state.activePrompt.folder);



    useEffect(() => {
        if (prompt)
            dispatch(selectPrompt(prompt));
    }, [prompt]);

    const tableInstance = useTable(
        {
            columns: COLUMNS,
            data: DATATABLE,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps, // table props from react-table
        headerGroups, // headerGroups, if your table has groupings
        getTableBodyProps, // table body props from react-table
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        state,
        setGlobalFilter,
        page, // use, page or rows
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
    }: any = tableInstance;

    const { globalFilter, pageIndex, pageSize }: any = state;

    // const [step, setStep] = useState(0);

    // function handleStep(step: any) {
    //     setStep(step);
    // }
    const currentStep = useSelector((state: any) => state.activePrompt?.wizardStep);
    // useEffect(() => {
    //     if (!tab || tab == "about") {
    //         setStep(0);
    //     } else {
    //         setStep(1);
    //     }
    // }, [tab]);

    console.log({ id, prompt, activeFolder, status })

    return (
        <div>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item main-content-title" linkAs={Link} linkProps={{ to: "/admin/prompts" }}>
                            Prompts
                        </Breadcrumb.Item>
                        {(id === "new" ? activeFolder?.slice(1) : prompt?.folder?.slice(1))?.map((f, index, array) =>
                            <Breadcrumb.Item key={f} className="breadcrumb-item main-content-title" linkAs={Link} linkProps={{ to: `/admin/prompts/${array.slice(0, index + 1).join("/")}` }}>
                                {f}
                            </Breadcrumb.Item>
                        )}
                        <Breadcrumb.Item className="breadcrumb-item main-content-title" active>
                            {prompt?.name ?? "New"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <Row>
                <Col lg={12} md={12}>

                    <div id="wizard3">
                        <Wizard step={currentStep?.step}>
                            {/* <Steps title="Prompt" number="1"> */}
                            <BasicInfo id={id ?? ""} />
                            {/* </Steps> */}
                            {/* <Steps title="Mapping" number="2" disabled={id === "new"}> */}
                            <PromptMapping />
                            {/* </Steps>
                            <Steps title="Test" number="3" disabled={id === "new"}> */}
                            <RunPrompt />
                            {/* </Steps> */}
                        </Wizard>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ManagePrompt;
