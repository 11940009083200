import {useEffect, useState} from "react";
import { collection, CollectionReference, deleteDoc, doc, setDoc } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { arraysEqual } from "../util/util";

export const ROOT_FOLDER = ["/"];

export default function useFolders<T extends {folder: string[], id: string}>(rootCollection: string, initialFolder: string[] = ROOT_FOLDER) {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const [currentFolder, setCurrentFolder] = useState<string[]>(initialFolder);
  const filesCollection = collection(firestore, rootCollection) as CollectionReference<T>;
  const { data: allFiles, status } = useFirestoreCollectionData(filesCollection, { idField: "id" });
  const [filesInChildFolders, setFilesInChildFolders] = useState<T[]>([]);
  const [folders, setFolders] = useState<{path: string, name: string, fileCount: number}[]>([]);
  const [files, setFiles] = useState<T[]>([]);

  useEffect(()=> {
    setFiles(allFiles?.filter((p) => arraysEqual(p.folder, currentFolder)));
    setFilesInChildFolders(allFiles?.filter(
      (p) => p.folder.length > currentFolder.length && arraysEqual(p.folder.slice(0, currentFolder.length), currentFolder)));
  }, [allFiles, currentFolder])

  useEffect(()=> {
    const folderNames = [...new Set(filesInChildFolders?.map(p => p.folder[currentFolder.length]))];
    const folderObjs = folderNames.map(f => ({
      path: [...currentFolder, f].join("/"),
      name: f, 
      fileCount: filesInChildFolders.filter(p => p.folder[currentFolder.length] == f).length}));
      // console.log({folderNames, currentFolder, folderObjs});
      setFolders(folderObjs);
  }, [filesInChildFolders, currentFolder]);

  const openFolder = (path: string) => {
    console.log("setting currnet folder", path, ["/", ...path.split("/").slice(2)]);
    setCurrentFolder(["/", ...path.split("/").slice(2)]);
  }

  const openChildFolder = (folderName: string) => {
    setCurrentFolder(prev => [...prev, folderName]);
  }

  const openBreadCrumb = (folderName: string) => {
    const path = currentFolder.slice(0,currentFolder.indexOf(folderName)+1).join("/")
    openFolder(path);
  }

  const saveFile = async (file: T) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await setDoc(fileRef, {...file, lastUpdated: new Date().toISOString()}, { merge: true });
    await setLoading(false);
  }
  const createFile = async (file: T) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await setDoc(fileRef, {...file, lastUpdated: new Date().toISOString()}, { merge: true });
    await setLoading(false);
  }

  const deleteFile = async (file: T) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await deleteDoc(fileRef);
    await setLoading(false);
  }

  return {currentFolder, files, folders, openFolder, openChildFolder, openBreadCrumb, status, saveFile, createFile, loading, deleteFile };
}