import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Card, Col, Form, FormGroup, ProgressBar, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../../redux/store/store';
import { Prompt } from '../../../../Firebase/models';
import useFolders from '../../../../hooks/useFolders';
import { FIRESTORE } from '../../../../Firebase/firestore';
import { selectPrompt } from '../../../../redux/active-prompt/activePromptSlice';
import Select from "react-select";
import { generateRandomId } from '../../../../util/util';
import { Router, useNavigate } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { MAX_TOKENS, SYS_FIELDS, TEMPLATE_FIELDS } from '../../../../util/promptutil';
import {encode, decodeGenerator} from "gpt-tokenizer";

export default function BasicInfo({ id }: { id: string }) {
    const { saveFile: savePrompt, createFile: createPrompt, loading: saving } = useFolders(FIRESTORE.PROMPT);
    const outputTypes = [{ value: "array", label: "array" }, { value: "function", label: "function" }, { value: "html", label: "html" }, { value: "json", label: "json" }, { value: "text", label: "text" }]
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeFolder = useSelector((state: RootState) => state.activePrompt.folder);
    const schema = yup
        .object({
            name: yup.string().required("Prompt name is a required field"),
            prompt: yup.string().required("Prompt text is a required field"),
            outputFormat: yup.string().optional(),
        })
        .required();
    const prompt = useSelector((state: RootState) => state.activePrompt.prompt);

    const { register, control, reset, handleSubmit, watch, formState: { isDirty, errors, isValid } } =
        useForm<Prompt>({ mode: "all", resolver: yupResolver(schema), defaultValues: prompt }); //, defaultValues: basicInfo
    const watchPromptText = watch("prompt", "");
    const watchOutputFormat = watch("outputFormat", "");


    const decodedTokens = useMemo(() => {
        const encodedTokens = encode(`${watchPromptText}, ${watchOutputFormat}`);
        const tokens = [];
      for (const token of decodeGenerator(encodedTokens)) {
        tokens.push(token);
      }
      return tokens;
    }, [watchPromptText, watchOutputFormat]);

    
    useEffect(() => {
        if (prompt) {
            // Reset the values in the form
            reset(prompt);
        }
    }, [prompt, reset]);
    console.log("id in basic info", id);
    const onSubmit = async (data: Prompt, e: any) => {
        let newId = generateRandomId();
        if (id === "new") {
            await createPrompt({
                ...data,
                id: data?.id?.length > 0 ? data?.id : newId,
                folder: activeFolder,
            });
        } else {
            await savePrompt(data);
        }
        console.log("dispatching", data);
        dispatch(selectPrompt(data));
        await navigate(`/admin/prompt/${id === "new" ? newId : data.id}`);
    }

    return (
        <section className="card-body p-4">
            <Form onSubmit={handleSubmit(onSubmit)} id="prompt-form">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Prompt Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Prompt Name"
                        {...register("name")}
                    />
                    <p className={"text-error"}>
                        {errors?.name?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Prompt Text *
                    </Form.Label>
                    <textarea
                        className="form-control"
                        {...register("prompt")}
                        placeholder="Prompt Text"
                        rows={10}
                    ></textarea>
                    <div className='text-wrap'>
                    <div className="progress mg-b-10">
                        <span className="badge bg-primary me-1">{decodedTokens.length} / {MAX_TOKENS} Tokens</span>
                    </div>                        
                    <span className="badge bg-light me-1">Special Fields:</span>
                    {Object.keys(SYS_FIELDS).map(field => 
                    <span className="badge bg-light me-1 my-2">({field})<CopyToClipboard text={`(${field})`}><button className="btn py-0 btn-link"><i className="fa fa-copy"></i></button></CopyToClipboard></span>
                    )}
                    {Object.keys(TEMPLATE_FIELDS).map(field => 
                    <span className="badge bg-light me-1 my-2">({field})<CopyToClipboard text={`(${field})`}><button className="btn py-0 btn-link"><i className="fa fa-copy"></i></button></CopyToClipboard></span>
                    )}
                    </div>

                    <p className={"text-error"}>
                        {errors?.prompt?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Functions
                    </Form.Label>
                    <textarea
                        className="form-control"
                        {...register("functions")}
                        placeholder="Functions"
                        rows={10}
                    ></textarea>
                    <p className={"text-error"}>
                        {errors?.functions?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Output Type
                    </Form.Label>
                    <Controller
                        control={control}
                        defaultValue="html"
                        name="outputType"
                        render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                                onChange={(sel) => onChange(sel?.value ?? "html")}
                                options={outputTypes}
                                value={outputTypes.find(c => c.value == value)}
                                classNamePrefix="formselect"
                                isSearchable
                                placeholder="Select output type"
                            />
                        )} />

                    <p className={"text-error"}>
                        {errors?.outputFormat?.message}
                    </p>
                </FormGroup>

                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Output Format
                    </Form.Label>
                    <textarea
                        className="form-control"
                        {...register("outputFormat")}
                        placeholder="json"
                        rows={4}
                    ></textarea>
                    <p className={"text-error"}>
                        {errors?.outputFormat?.message}
                    </p>
                </FormGroup>
                <FormGroup className="control-group form-group">
                    {saving ?
                        <div className="text-center">
                            <div className="lds-dual-ring"></div>
                        </div>
                        :
                        <button className="btn btn-primary " type={"submit"}>
                            Save
                        </button>
                    }
                </FormGroup>
            </Form>
        </section>
    );
}
