import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, FormGroup, ProgressBar, Row } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { RootState } from '../../../../redux/store/store';
import { Prompt } from '../../../../Firebase/models';
import useFolders from '../../../../hooks/useFolders';
import { FIRESTORE } from '../../../../Firebase/firestore';
import Select from "react-select";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, CollectionReference } from "firebase/firestore";
import { Product } from "../../../../models/product";
import useChatGpt from '../../../../hooks/useChatGPT';
import { asString, substituteShallow, tokens, valueForKeyPath } from '../../../../util/promptutil';
import { PromptData } from './models';
import useProducts from '../../../../hooks/useProducts';
import useMarketingCampaign from '../../../../hooks/prompts/useMarketingCampaign';
import TokenizedText from '../TokenizedText';
import { ChatGPTFunctionCall } from '../../../../models/chat';


export default function RunPrompt() {
    const {testDatasets: datasetNamesList} = useMarketingCampaign();

    const { loading, runStringPrompt, runFunctionPrompt } = useChatGpt();
    const [selectedDataset, setSelectedDataset] = useState<PromptData | undefined>();
    const prompt: Prompt = useSelector((state: RootState) => state.activePrompt.prompt);
    const substitutionTokens = tokens(prompt.prompt);
    const [input, setInput] = useState(prompt?.prompt ?? "");
    const [output, setOutput] = useState<string | ChatGPTFunctionCall>("");
    const [showTokens, setShowTokens]= useState(false);
    const [overrides, setOverrides] = useState<Record<string,string>>({});

    const schema = yup
        .object({
            name: yup.string().required("Prompt name is a required field"),
            prompt: yup.string().required("Prompt text is a required field"),
            outputFormat: yup.string().required("Output Format is a required field"),
        })
        .required();

    const { register, control, reset, handleSubmit, formState: { isDirty, errors, isValid } } =
        useForm<Prompt>({ mode: "all", resolver: yupResolver(schema), defaultValues: prompt }); //, defaultValues: basicInfo

    useEffect(() => {
        if (prompt) {
            // Reset the values in the form
            reset(prompt);
        }
    }, [prompt, reset]);


    useEffect(() => {
        if (prompt) {
            if(!!selectedDataset) {
                substitutionTokens?.map(t => {
                    const content = asString(valueForKeyPath(selectedDataset?.data, prompt?.substitutions?.[t]));
                    setOverrides(prev => ({...prev, [t]: content}))
                })
            } else {
                setInput(prompt.prompt);
            }
        }
    }, [prompt, selectedDataset]);

    useEffect(() => {
        if (prompt) {
            if(!!overrides) {
                const replaced = substituteShallow(prompt.prompt, overrides);
                setInput(replaced);
            } else {
                setInput(prompt.prompt);
            }
        }
    }, [prompt, overrides])

    const onSubmit = async (data: Prompt, e: any) => {
        const response = prompt.functions ?  await runFunctionPrompt(input,  prompt.functions, prompt.outputFormat) :  
                                             await runStringPrompt(input,  prompt.functions, prompt.outputFormat);
        setOutput(response);
    }

    return (
        <section className="card-body p-4" id="Test">
            <Form onSubmit={handleSubmit(onSubmit)} id="prompt-form">
                <FormGroup className="control-group form-group text-black">
                    <Form.Label className="form-label">
                        Product
                    </Form.Label>
                    <Select
                        onChange={(sel) =>
                            setSelectedDataset(sel?.value)
                        }
                        options={datasetNamesList}

                        value={datasetNamesList?.find(p => p.value.id == selectedDataset?.id)}
                        classNamePrefix="formselect"
                        isSearchable
                        placeholder="Select a dataset"
                    />
                </FormGroup>
                <FormGroup className='control-group form-group'>
                <Form.Label className="form-label">
                        <b>Overrides</b>
                    </Form.Label>

                {substitutionTokens?.map(t => {
                    const content = asString(valueForKeyPath(selectedDataset?.data, prompt?.substitutions?.[t]));
                    return (
                <Row className="row-xs align-items-center mg-b-20">
                    <Col md={3}>
                      <Form.Label className="form-label mg-b-0">
                        {t}{" "}
                      </Form.Label>
                    </Col>
                    <Col md={5} className=" mg-t-5 mg-md-t-0">
                    <input type="text" className='form-control' name={t} defaultValue={content} onChange={(e) => setOverrides(prev => ({...prev, [t]: e.target.value}))} />
                    </Col>
                </Row>)})}
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Prompt Preview
                    </Form.Label>
                    <div className="checkbox">
                  <div className="custom-checkbox custom-control">
                    <Form.Control
                      type="checkbox"
                      data-checkboxes="mygroup"
                      className="custom-control-input"
                      id="checkbox-show-tokens"
                      onClick={()=> setShowTokens(!showTokens)}
                    />
                    <Form.Label
                      htmlFor="checkbox-show-tokens"
                      className="custom-control-label mt-1"
                    >
                      Show Tokens
                    </Form.Label>
                  </div>
                </div>
                {showTokens ?
                    <TokenizedText text={`${input}, ${prompt.outputFormat ?? "return as a string array"}`} /> :
                    <textarea
                        className="form-control"
                        placeholder="Prompt Text"
                        rows={10}
                        value={`${input}, ${prompt.outputFormat ?? "return as a string array"}`}
                    ></textarea>
                }
                </FormGroup>
                <FormGroup className="control-group form-group">
                    {loading ?
                        <div className="text-center">
                            <div className="lds-dual-ring"></div>
                        </div>
                        :
                        <button className="btn btn-primary " type={"submit"}>
                            Generate
                        </button>
                    }
                </FormGroup>
                <FormGroup className="control-group form-group">
                    {prompt.outputType == "html" ?
                        <div dangerouslySetInnerHTML={{ __html: output as string }} />
                        : typeof output === "object" ? 
                            <pre>{(() => {
                                console.log(output);
                               const tmp: any = output;
                               if(typeof tmp.arguments === "string")
                                tmp.arguments = JSON.parse(tmp.arguments);
                               return JSON.stringify(tmp, null, 4);
                            })()}</pre>
                        :
                        <p >
                            {output as string}
                        </p>}
                </FormGroup>
            </Form>

        </section>
    );
}
