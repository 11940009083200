import {
    Card,
    Col,
    Row
} from "react-bootstrap";
import { useSelector } from 'react-redux';
import Profile from "./Profile";
import Wizard from './Wizard'
import Goals from "../Campaign/Manage/CampaignGoals";
import Communication from "./Communication";
import ProflieGoals from "./ProfileGoals";
import { RootState } from "../../../redux/store/store";



const Steps = ({ children }: any) => children;

function ManageProfile() {
    const currentStep = useSelector((state: RootState) => state.accountStep);

    return (
        <div>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                        Profile Information
                    </span>
                </div>
            </div>
            <Row>
                <Col lg={12} md={12}>
                    <div id="wizard3">
                        <Wizard step={currentStep?.step}>
                            <Profile />
                            {/* <ProflieGoals /> */}
                            <Communication />

                        </Wizard>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ManageProfile;
