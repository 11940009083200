import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    Col,
    Row
} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Wizard from "./Wizard";
import CompanyInfo from "./CompanyInfo";
import CompetitorInfo from "./CompetitorInfo";
import { useNavigate, useParams } from "react-router-dom";
import { addCompanyBasicInfo, addCompanyCompetitors } from "../../../../redux/active-item/active-itemSlice";
import useCompanies from "../../../../hooks/useCompanies";

function ManageCompany() {
    const { id, tab } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {companies, deleteCompany} = useCompanies();
    const company = companies?.[0];

    useEffect(() => {
        if(id === "new" && company?.id){
            navigate(`/pages/company/${company?.id}`);
        }
    }, [company, id])    

    const currentStep = useSelector((state: any) => state.companyStep);

    useEffect(() => {
        if(company?.id && company?.createdAt){
            dispatch(addCompanyBasicInfo({
                name: company?.name,
                numberOfEmployees: company?.numberOfEmployees,
                crm: company?.crm,
                industry: company?.industry,
                location: company?.location,
                mission: company?.mission,
                revenue: company?.revenue,
                usp: company?.usp,
                value: company?.value,
            }));   
            dispatch(addCompanyCompetitors(company?.competitors ?? []));
        }
    }, [company])

    return (
        <div>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
                    <span className="main-content-title mg-b-0 mg-b-lg-1">
                        {id === "new" ? "New" : `${company?.name}`}
                    </span>
                </div>
            </div>
            <Row>
                <Col lg={12} md={12}>
                    <div id="wizard3">
                        <Wizard step={currentStep?.step}>
                            <CompanyInfo id={id} company={company} />
                            <CompetitorInfo id={id} company={company} />
                        </Wizard>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default ManageCompany;
