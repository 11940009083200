import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import { Company } from '../../../../models/company';
import { RootState } from '../../../../redux/store/store';
import { addCompanyCrm, addCompanyIndustry, addCompanyLocation, addCompanyMission, addCompanyName, addCompanyNumberOfEmployees, addCompanyRevenue, addCompanyUsp, addCompanyValue } from '../../../../redux/active-item/active-itemSlice';
import { titleUpperCase } from '../../../../util/util';

function CompanyInfo({ company, id }: { company: Company; id?: string; }) {
    const dispatch = useDispatch();

    const basicInfo = useSelector((state: RootState) => state.activeItem.activeCompany.basicCompanyInfo);

    return (
        <section className="card-body p-4">
            <Form id="Company Info">
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Company Name *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        value={basicInfo?.name}
                        onChange={(e) => dispatch(addCompanyName(e?.currentTarget?.value))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        Industry *
                    </Form.Label>
                    <div className="SlectBox text-black">
                        <Select
                            options={[
                                { value: "technology", label: "Technology" },
                                { value: "healthcare", label: "Healthcare" },
                                { value: "finance", label: "Finance" },
                                { value: "retail", label: "Retail" },
                                { value: "energy", label: "Energy" },
                                { value: "manufacturing", label: "Manufacturing" },
                                { value: "hospitality and tourism", label: "Hospitality and tourism" },
                                { value: "education", label: "Education" },
                                { value: "agriculture", label: "Agriculture" },
                                { value: "construction", label: "Construction" }
                            ]}
                            placeholder="Select Industry"
                            classNamePrefix="formselect"
                            defaultValue={{ value: basicInfo?.industry, label: titleUpperCase(basicInfo?.industry ?? "") }}
                            value={{ value: basicInfo?.industry, label: titleUpperCase(basicInfo?.industry ?? "") }}
                            onChange={(e) => dispatch(addCompanyIndustry(e?.value ?? ""))}
                        />
                    </div>

                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Number of Employees
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control"
                        placeholder="Employees"
                        value={basicInfo?.numberOfEmployees}
                        onChange={(e) => dispatch(addCompanyNumberOfEmployees(parseFloat(e?.currentTarget?.value ?? "0")))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Revenue *
                    </Form.Label>
                    <Form.Control
                        type="number"
                        className="form-control"
                        placeholder="$50,000"
                        value={basicInfo?.revenue}
                        onChange={(e) => dispatch(addCompanyRevenue(parseFloat(e?.currentTarget?.value ?? "0")))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Location
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="i.e Houston"
                        value={basicInfo?.location}
                        onChange={(e) => dispatch(addCompanyLocation(e?.currentTarget?.value))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Mission *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Mission"
                        value={basicInfo?.mission}
                        onChange={(e) => dispatch(addCompanyMission(e?.currentTarget?.value))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Value *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Value"
                        value={basicInfo?.value}
                        onChange={(e) => dispatch(addCompanyValue(e?.currentTarget?.value))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label className="form-label">
                        Unique selling points (USPs) and value proposition *
                    </Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="(USPs)"
                        value={basicInfo?.usp}
                        onChange={(e) => dispatch(addCompanyUsp(e?.currentTarget?.value))}
                    />
                </FormGroup>
                <FormGroup className="control-group form-group">
                    <Form.Label>
                        CRM *
                    </Form.Label>
                    <div className="SlectBox text-black">
                        <Select
                            options={[
                                { value: "hubspot", label: "Hubspot" },
                                { value: "salesforce", label: "SalesForce" },
                                { value: "mailchimp", label: "Mailchimp" },
                            ]}
                            placeholder="Select CRM"
                            classNamePrefix="formselect"
                            defaultValue={{ value: basicInfo?.crm, label: titleUpperCase(basicInfo?.crm ?? "") }}
                            value={{ value: basicInfo?.crm, label: titleUpperCase(basicInfo?.crm ?? "") }}
                            onChange={(e) => dispatch(addCompanyCrm(e?.value ?? ""))}
                        />
                    </div>

                </FormGroup>
            </Form>
        </section>
    )
}

export default CompanyInfo;