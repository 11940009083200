import {encode, decodeGenerator} from "gpt-tokenizer";
import { useMemo } from "react";
import { ProgressBar } from "react-bootstrap";
import { MAX_TOKENS } from "../../../util/promptutil";

const pastelColors = [
  "bg-success", "bg-warning", "bg-info"
]; //"bg-secondary", "bg-danger", "bg-primary", , "bg-dark"

const monospace = `"Roboto Mono",sfmono-regular,consolas,liberation mono,menlo,courier,monospace`;

export default function TokenizedText({ text }: { text: string}) {
  const encodedTokens = encode(text);

  const decodedTokens = useMemo(() => {
    const tokens = [];
    for (const token of decodeGenerator(encodedTokens)) {
      tokens.push(token);
    }
    return tokens;
  }, [encodedTokens]);
  const percentUsed = (decodedTokens.length * 100)/MAX_TOKENS;
  return (
    <div className="text-wrap">
      <div className="progress mg-b-10">
        {percentUsed > 50 ?
          <ProgressBar
              variant={percentUsed > 70 ? "secondary" : percentUsed > 50 ? "warning" : "primary"}
              now={percentUsed}
              label={`${decodedTokens.length} / ${MAX_TOKENS} Tokens (${percentUsed.toFixed(0)}%)`}
              className="progress-bar-lg"
          /> :
          <span className="badge bg-dark me-1">{decodedTokens.length} / {MAX_TOKENS} Tokens ({percentUsed.toFixed(0)}%)</span>
        }         
      </div>                        

      <div style={{fontFamily: monospace, height: 300, overflow: "scroll"}}>
      {decodedTokens.map((token, index) => (
        <>
        {String(token).includes("\n") ? 
        <p>
          <span className={`badge ${pastelColors[index % pastelColors.length]} me-0 my-0`}>
            {String(token)
               .replaceAll(" ", "\u00A0")
               .replaceAll("\n", "<newline>")}
        </span>
        </p> : 
        <span className={`badge ${pastelColors[index % pastelColors.length]} me-0 my-0`}>
            {String(token)
               .replaceAll(" ", "\u00A0")
               .replaceAll("\n", "<newline>")}
        </span>
        }
      </>
      ))}
      </div>
    </div>)

}